export class LimitOffsetPaging {
  count: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;

  static get empty(): LimitOffsetPaging {
    const paging = new LimitOffsetPaging();
    paging.count = 0;
    paging.currentPage = 1;
    paging.pageSize = 10;
    paging.totalPages = 0;
    return paging;
  }

  static fromQueryParams(queryParams = {}, fallback = {}): LimitOffsetPaging {
    const paging = new LimitOffsetPaging();
    paging.count = 0;
    paging.currentPage = Number(queryParams['page']) || 1;
    paging.pageSize = Number(queryParams['pageSize']) || Number(fallback['pageSize']) || 10;
    paging.totalPages = 0;
    return paging;
  }
}

export class Sort {
  col: string;
  dir: string;

  static fromQueryParams(queryParams = {}, fallback = {}): Sort {
    const sort = new Sort();
    sort.col = queryParams['sortCol'] || fallback['col'] || null;
    sort.dir = queryParams['sortDir'] || fallback['dir'] || null;
    return sort;
  }

  constructor(col: string = null, dir: string = null) {
    this.col = col;
    this.dir = dir;
  }
}

export class Paging {
  page?: number;
  pageSize: number | 'all';

  constructor(page: number, pageSize: number | 'all') {
    this.page = page;
    this.pageSize = pageSize;
  }
}

export class LimitOffsetPagingMeta {
  paging: LimitOffsetPaging;
  sort: Sort;
}
