import { LimitOffsetPaging, Sort, Paging } from '../paged-results/limit-offset-paging.model';
import { UserQueueSettings } from '../../interfaces/user-queue-settings';

export class TaskFilters {
  selectedClientIds: number[] = new Array<number>();
  selectedStatuses: string[] = new Array<string>();
  selectedEncounterPatientInteractionLocationIds: number[] = new Array<number>();
  estimatedValue: string = null;
  expiringWithinDays: number = null;
  selectedSpecialties: string[] = new Array<string>();
  selectedManufacturers: string[] = new Array<string>();
  selectedEstimatedValues: number[] = new Array<number>();
  selectedSources: string[] = new Array<string>();
  prescriberFirstName: string = null;
  prescriberLastName: string = null;
  prescriberNpi: string = null;
  patientFirstName: string = null;
  patientLastName: string = null;
  taskId: string = null;
  patientId: number = null;
}

export class TaskListSettings {
  filters: TaskFilters = new TaskFilters();
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  sort: Sort = new Sort();
}

export class TaskQueueSettings extends TaskListSettings implements UserQueueSettings {
  position: number = null;
}

const taskFiltersToURLSearchParams = (
  filters: TaskFilters,
  paging: Paging,
  sort: Sort,
  legacySortColumn: string = null): URLSearchParams => {
  const searchParams = new URLSearchParams(`page=${paging.page}&per_page=${paging.pageSize}`);

  if (filters) {
    if (filters.estimatedValue) {
      searchParams.append('estimated_value_at_least', filters.estimatedValue.toString());
    }

    if (filters.expiringWithinDays) {
      searchParams.append('expiring_within_days', filters.expiringWithinDays.toString());
    }

    if (filters.prescriberFirstName) {
      searchParams.append('prescriber_first_name', filters.prescriberFirstName);
    }

    if (filters.prescriberLastName) {
      searchParams.append('prescriber_last_name', filters.prescriberLastName);
    }

    if (filters.prescriberNpi) {
      searchParams.append('prescriber_npi', filters.prescriberNpi);
    }

    if (filters.patientFirstName) {
      searchParams.append('patient_first_name', filters.patientFirstName);
    }

    if (filters.patientLastName) {
      searchParams.append('patient_last_name', filters.patientLastName);
    }

    if (filters.selectedStatuses) {
      filters.selectedStatuses.forEach(status => searchParams.append('statuses[]', status));
    }

    if (filters.selectedSpecialties) {
      filters.selectedSpecialties.forEach(specialty => searchParams.append('specialties[]', specialty));
    }

    if (filters.selectedManufacturers) {
      filters.selectedManufacturers.forEach(manufacturer => searchParams.append('manufacturers[]', manufacturer.toString()));
    }

    if (filters.selectedSources) {
      filters.selectedSources.forEach(source => searchParams.append('sources[]', source));
    }

    if (filters.selectedEstimatedValues) {
      filters.selectedEstimatedValues.forEach(estimatedValue =>
        searchParams.append('estimated_values[]', estimatedValue.toString())
      );
    }

    if (filters.selectedClientIds) {
      filters.selectedClientIds.forEach(clientId => searchParams.append('client_ids[]', clientId.toString()));
    }

    if (filters.selectedEncounterPatientInteractionLocationIds) {
      filters.selectedEncounterPatientInteractionLocationIds.
        forEach(locationId => searchParams.append('encounter_patient_interaction_location_ids[]',
          locationId.toString()));
    }

    if (filters.taskId) {
      searchParams.append('task_id', filters.taskId);
    }

    if (filters.patientId) {
      searchParams.append('patient_id', filters.patientId.toString());
    }
  }

  if (sort && sort.col && sort.dir && sort.col !== legacySortColumn) {
    searchParams.append('sort_col', sort.col);
    searchParams.append('sort_dir', sort.dir);
  }

  return searchParams;
}

export { taskFiltersToURLSearchParams };
