import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { InboundFax } from '../../../core/models/inbound-fax.model';
import { Router } from '@angular/router';
import { InboundFaxesService } from '../../../core/services/inbound-faxes.service';
import { Patient } from '../../../core/models/patient.model';
import { Prescriber } from '../../../core/models/prescriber.model';
import { InboundFaxOutcome } from '../inbound-fax-select-outcome/inbound-fax-select-outcome.component';
import { InboundFaxStatus } from '../../../core/enums/inbound-fax-status.enum';
import { InboundFaxStatusReason } from '../../../core/enums/inbound-fax-status-reason.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { OutboundFax } from '../../../core/models/outbound-fax.model';
import { InboundFaxCommunicationMethod } from 'app/core/enums/inbound-fax-communication-method.enum';
import { SimilarInboundFaxState } from 'app/capture-admin/pages/capture-admin-inbound-fax/capture-admin-inbound-fax.component';

export enum InboundFaxAttributeChangeType {
  patient = 'patient',
  prescriber = 'prescriber',
  outboundFax = 'outboundFax',
  outcome = 'outcome',
  communicationMethod = 'communicationMethod',
  multiplePatientFeedback = 'multiplePatientFeedback',
}

@Component({
  selector: 'app-inbound-fax-process-form',
  templateUrl: './inbound-fax-process-form.component.html',
  styleUrls: ['./inbound-fax-process-form.component.scss'],
})
export class InboundFaxProcessFormComponent implements OnInit {
  serverError = false;
  validationErrors = new Array<string>();

  _similarInboundFaxState = new SimilarInboundFaxState();

  @Input() inboundFax: InboundFax;
  @Input() set similarInboundFaxState(newState: SimilarInboundFaxState) {
    this._similarInboundFaxState = newState;
    this.clearValidationErrors();
  }

  @Output() inboundFaxAttributeChanged = new EventEmitter<InboundFaxAttributeChangeType>();
  @Output() tabChangeRequested = new EventEmitter<string>();
  @Output() nextFaxRequested = new EventEmitter<void>();
  @Output() skipToNextFaxRequested = new EventEmitter<void>();
  @Output() navigateToQueueRequested = new EventEmitter<void>();

  @ViewChild('selectPatient', { static: true })
  selectPatient;

  junkNonMedicalDocumentSelected = false;
  showMultiplePatientsWarning = false;

  constructor(
    private inboundFaxesService: InboundFaxesService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.setJunkNonMedicalDocumentSelected();
    this.ensureValidCommunicationMethod();
    this.setShowMultiplePatientsWarning();
  }

  get similarInboundFaxState() {
    return this._similarInboundFaxState;
  }

  onPatientSelectionChange(patient: Patient): void {
    this.inboundFax.sourcePatient = patient;

    if (patient && patient.mainPatient) {
      this.inboundFax.patient = patient.mainPatient;
    } else if (patient) {
      this.inboundFax.patient = patient;
    } else {
      this.inboundFax.patient = null;
    }

    this.inboundFax.prescriber = null;
    this.handleAttributeChange(InboundFaxAttributeChangeType.patient);
  }

  onPrescriberSelectionChange(prescriber: Prescriber): void {
    this.inboundFax.prescriber = prescriber;
    this.inboundFax.provider = prescriber?.provider;
    this.handleAttributeChange(InboundFaxAttributeChangeType.prescriber);
  }

  onOutboundFaxChange(outboundFax: OutboundFax): void {
    this.inboundFax.outboundFax = outboundFax;
    this.inboundFax.communicationMethod = null;
    this.ensureValidCommunicationMethod();
    this.handleAttributeChange(InboundFaxAttributeChangeType.outboundFax);
  }

  onFaxOutcomeChange(outcome: InboundFaxOutcome): void {
    this.inboundFax.status = outcome.status;
    this.inboundFax.notes = outcome.notes;

    if (this.inboundFax.status === InboundFaxStatus.notValidEvidence) {
      this.inboundFax.statusReason = outcome.statusReason;
    } else {
      this.inboundFax.statusReason = null;
    }

    if (this.inboundFax.status === InboundFaxStatus.needsManualIntervention) {
      this.clearPatientAndPrescriber();
    }

    this.setJunkNonMedicalDocumentSelected();
    this.ensureValidCommunicationMethod();
    this.handleAttributeChange(InboundFaxAttributeChangeType.outcome);
  }

  onCommunicationMethodChange(communicationMethod: string): void {
    this.inboundFax.communicationMethod = communicationMethod;
    this.handleAttributeChange(InboundFaxAttributeChangeType.communicationMethod);
  }

  onMultiplePatientFeedbackChange(valid: boolean): void {
    this.inboundFax.llmPatientsResultValid = valid;
    this.handleAttributeChange(InboundFaxAttributeChangeType.multiplePatientFeedback);
  }

  onSaveClick() {
    this.serverError = false;

    if (this.validate()) {
      this.inboundFaxesService.transition(this.inboundFax).subscribe(
        () => {
          this.nextFaxRequested.emit();
        },
        (err: HttpErrorResponse) => {
          this.serverError = true;
          console.error(err);
        }
      );
    }
  }

  onCancelClick() {
    this.navigateToQueueRequested.emit();
  }

  onSkipToNextClick() {
    this.skipToNextFaxRequested.emit();
  }

  onReviewDuplicatesClick() {
    this.tabChangeRequested.emit("duplicate-documents");
  }

  duplicateCheckNotComplete() {
    return this.similarInboundFaxState.fetched === false ||
      (this.similarInboundFaxState.similarInboundFaxes.length > 0 &&
        !this.similarInboundFaxState.warningDismissed);
  }

  public get selectedCommunicationMethod() {
    return this.inboundFax.communicationMethod;
  }

  private handleAttributeChange(type: InboundFaxAttributeChangeType) {
    this.inboundFaxAttributeChanged.emit(type);
    this.clearValidationErrors();
  }

  private clearPatientAndPrescriber() {
    this.inboundFax.patient = null;
    this.inboundFax.prescriber = null;
    this.selectPatient.resetSearchForm();

    this.handleAttributeChange(InboundFaxAttributeChangeType.patient);
    this.handleAttributeChange(InboundFaxAttributeChangeType.prescriber);
  }

  private clearValidationErrors() {
    if (this.validationErrors.length > 0) {
      this.validationErrors = new Array<string>();
    }
  }

  private validate(): boolean {
    this.clearValidationErrors();

    if (this.inboundFax.status === InboundFaxStatus.validEvidence ||
      this.inboundFax.status === InboundFaxStatus.notValidEvidence) {
      if (this.patientAndPrescriberRequired() && !this.inboundFax.patient) {
        this.validationErrors.push('Patient is required.');
      }

      if (this.patientAndPrescriberRequired() && !this.inboundFax.prescriber) {
        this.validationErrors.push('Prescriber is required.');
      }

      if (this.reasonRequired() && !this.inboundFax.statusReason) {
        this.validationErrors.push('Reason is required.');
      }

      if (this.notesRequired() && !(this.inboundFax.notes && this.inboundFax.notes.length > 0)) {
        this.validationErrors.push('Additional details are required.');
      }

      if (this.outboundFaxRequired() && !this.inboundFax.outboundFax) {
        this.validationErrors.push('Outbound fax is required.');
      }

      if (this.outboundFaxRequired() && this.duplicateCheckNotComplete()) {
        this.validationErrors.push('Fax must be checked for duplicates before submitting.');
      }

      if (this.showMultiplePatientsWarning && this.inboundFax.llmPatientsResultValid === undefined) {
        this.validationErrors.push('Please indicate multiple patient validity.');
      }
    }

    return this.validationErrors.length === 0;
  }

  private setJunkNonMedicalDocumentSelected() {
    this.junkNonMedicalDocumentSelected =
      this.inboundFax.status === InboundFaxStatus.notValidEvidence &&
      this.inboundFax.statusReason === InboundFaxStatusReason.junkNonMedicalDocument;
  }

  private ensureValidCommunicationMethod() {
    if (this.junkNonMedicalDocumentSelected) {
      this.inboundFax.communicationMethod = null;
    } else {
      this.inboundFax.communicationMethod =
        this.inboundFax.communicationMethod ||
        this.inboundFax.outboundFax?.office?.communicationMethod ||
        InboundFaxCommunicationMethod.standardFax;
    }
  }

  private reasonRequired() {
    return this.inboundFax.status === InboundFaxStatus.notValidEvidence;
  }

  private notesRequired() {
    return this.inboundFax.status === InboundFaxStatus.notValidEvidence &&
      this.inboundFax.statusReason === InboundFaxStatusReason.other;
  }

  private patientAndPrescriberRequired() {
    return this.inboundFax.statusReason !== InboundFaxStatusReason.junkNonMedicalDocument;
  }

  private outboundFaxRequired() {
    return this.patientAndPrescriberRequired();
  }

  private duplicateCheckRequired() {
    return this.patientAndPrescriberRequired();
  }

  private setShowMultiplePatientsWarning() {
    if (this.inboundFax.latestLlmResultId && this.inboundFax.possiblePatientInfo.length > 1) {
      this.showMultiplePatientsWarning = true;
    }
  }

}
