import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ClientPrescriberTask } from '../models/client-prescriber-task.model';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { ApiService } from './api.service';
import { NextQueueItem } from '../models/next-queue-item.model';
import { UserSettings } from '../enums/user-settings.enum';
import { QueueProcessingService } from './queue-processing.service';
import { ClientPrescriberTaskStatus } from '../enums/client-prescriber-task-status.enum';
import { NextQueueItemProvider } from '../interfaces/next-queue-item-provider';
import { AuthenticationService } from './authentication.service';
import { ClientPrescriberTaskCapture } from '../models/capture-lists/client-prescriber-task-capture.model';
import { LimitOffsetPagingMeta, Sort, Paging } from '../models/paged-results/limit-offset-paging.model';
import { TaskFilters, taskFiltersToURLSearchParams } from '../models/user-settings/task-settings.model';
import { ClientTaskHistoryItem } from '../models/client-task-history-item.model';

class ClientPrescriberTasksMeta extends LimitOffsetPagingMeta {
  clientTasksVisibleCount: number;
  clientTasksMinValue: number;
}

export class PagedClientPrescriberTasks implements PagedResults {
  clientPrescriberTasks: ClientPrescriberTask[];
  meta: ClientPrescriberTasksMeta;

  get records() {
    return this.clientPrescriberTasks;
  }
}

class NextClientPrescriberTaskProvider implements NextQueueItemProvider {
  constructor(private clientPrescriberTasksService: ClientPrescriberTasksService) {}

  nextItemInQueueRequest(currentPage: number, pageSize: number, filters: TaskFilters) {
    return this.nextClientTaskRequest(currentPage, pageSize, filters);
  }

  nextItemFromStartRequest() {
    return this.nextClientTaskRequest(1, 1, null);
  }

  private nextClientTaskRequest(currentPage: number, pageSize: number, filters: TaskFilters) {
    const paging = { page: currentPage, pageSize };

    if (!filters) {
      filters = Object.assign(new TaskFilters(), { selectedStatuses: [ClientPrescriberTaskStatus.open] });
    }

    return this.clientPrescriberTasksService.getList(paging, filters).pipe(
      map(resultsData => {
        const results = new PagedClientPrescriberTasks();
        return Object.assign(results, resultsData);
      })
    );
  }
}

@Injectable()
export class ClientPrescriberTasksService extends ApiService {
  constructor(
    authService: AuthenticationService,
    private http: HttpClient,
    private queueProcessingService: QueueProcessingService
  ) {
    super();
  }

  get(id: number): Observable<ClientPrescriberTask> {
    return this.http.get<ClientPrescriberTask>(`${environment.captureApi.url}/client_prescriber_tasks/${id}`);
  }

  getByCapture(id: number): Observable<ClientPrescriberTask> {
    return this.http.get<ClientPrescriberTask>(`${environment.captureApi.url}/captures/${id}/client_prescriber_task`);
  }

  getList(
    paging: Paging,
    filters: TaskFilters = null,
    sort: Sort = null
  ): Observable<PagedClientPrescriberTasks> {
    const searchParams = taskFiltersToURLSearchParams(filters, paging, sort);
    return this.http.get<PagedClientPrescriberTasks>(
      `${environment.captureApi.url}/client_prescriber_tasks?${searchParams.toString()}`
    );
  }

  getSources(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.captureApi.url}/client_prescriber_tasks/sources`);
  }

  getManufacturers(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.captureApi.url}/client_prescriber_tasks/manufacturers`);
  }

  getCaptures(task: ClientPrescriberTask): Observable<ClientPrescriberTaskCapture[]> {
    return this.http.get<ClientPrescriberTaskCapture[]>(
      `${environment.captureApi.url}/client_prescriber_tasks/${task.id}/captures`
    );
  }

  transition(id: number, taskTransition: string, notes: string): Observable<ClientPrescriberTask> {
    return this.http.patch<ClientPrescriberTask>(
      `${environment.captureApi.url}/client_prescriber_tasks/${id}/transition/${taskTransition}`,
      {
        task: { notes },
      }
    );
  }

  getNextClientTask(): Observable<NextQueueItem<ClientPrescriberTask>> {
    const nextClientTaskProvider = new NextClientPrescriberTaskProvider(this);
    return this.queueProcessingService.getNextItem<ClientPrescriberTask>(
      UserSettings.clientPortalPrescriberTaskQueueSettings,
      nextClientTaskProvider
    );
  }

  getClientTaskHistoryItems(id: number): Observable<ClientTaskHistoryItem[]> {
    return this.http.get<ClientTaskHistoryItem[]>(
      `${environment.captureApi.url}/client_prescriber_tasks/${id}/history_items`
    );
  }
}
