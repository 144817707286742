<div *ngIf="!loading; else: spinner">
  <app-consult-note-title [patientAttachment]="patientAttachment" />
  <div class="row">
    <div class="col-5">
      <div class="border rounded container-fluid p-0 mb-1">
        <div class="mx-1 row small-gutters">
          <div class="col-auto">
            <strong>CLIENT</strong>
            <div>
              <a routerLink="/capture-admin/client-management/clients/{{patientAttachment.client.id}}">
                {{ patientAttachment.client.humanizedName }}
              </a>
            </div>
          </div>
          <div class="col-auto">
            <strong>CE-PATIENT</strong>
            <app-patient-name [patient]="patientAttachment.patient" [canViewPatient]=true/>
          </div>
          <div class="col-auto">
            <strong>OFFICE</strong>
            <app-consult-note-office [faxedProviderOffice]="patientAttachment.faxedProviderOffice" />
          </div>
        </div>
      </div>
      <h6 class="p-0 mt-3 mb-0 fw-bold text-black">
        RELATED CONSULT NOTES FOR PATIENT & OFFICE
      </h6>
      <a routerLink="/capture-admin/patients/{{patientAttachment.patient.id}}/documents">View all consult notes</a>
      for {{ patientAttachment.patient.fullName }} ({{ patientAttachment.patient.dob | date: 'MM/dd/yyyy' }})
      <ng-container *ngIf="patientAttachment.relatedPatientAttachments.length > 0; else noAttachments">
        <table class="table table-sm table-bordered table-hover">
          <thead>
            <tr>
              <th class="min-width">ID</th>
              <th>DOCUMENT NAME</th>
            </tr>
          </thead>
          <tbody>
            <tr
              [ngClass]="patientAttachment.listing.id === selectedAttachment?.id ? 'selected' : 'clickable'"
              (click)="onDisplayDocument(patientAttachment.listing.id)" >
              <td class="min-width">{{ patientAttachment.listing.id }}</td>
              <td>{{ patientAttachment.listing | patientAttachmentListingDisplayName }} [CURRENT NOTE]</td>
            </tr>
            <tr *ngFor="let document of patientAttachment.relatedPatientAttachments"
              [ngClass]="document.id === selectedAttachment?.id ? 'selected' : 'clickable'"
              (click)="onDisplayDocument(document.id)" >
              <td class="min-width">{{ document.id }}</td>
              <td>{{ document | patientAttachmentListingDisplayName }}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-template #noAttachments>No related consult notes.</ng-template>
    </div>
    <div class="col-7">
      <div *ngIf="!selectedLoading; else: selectedSpinner">
        <h6 class="p-0 m-0 fw-bold text-black-50">CONSULT NOTE INFORMATION</h6>
        <div class="border rounded container-fluid p-0 mb-2 bg-light">
          <div class="mx-1 row small-gutters">
            <div class="col-auto">
              <strong>ID</strong>
              <div>{{ selectedAttachment.id }}</div>
            </div>
            <div class="col-auto">
              <strong>STATUS</strong>
              <div>{{ statusOptions[selectedAttachment.status]?.display }}</div>
            </div>
            <div class="col-auto">
              <strong>OFFICE</strong>
              <app-consult-note-office [faxedProviderOffice]="selectedAttachment.faxedProviderOffice" />
            </div>
          </div>
        </div>
        <h6 class="p-0 m-0 mt-2 fw-bold text-black-50">FAX INFORMATION</h6>
        <div class="border rounded container-fluid p-0 mb-2 bg-light">
          <div class="mx-1 row small-gutters">
            <div class="col-auto">
              <strong>STATUS</strong>
              <div>{{ selectedAttachment.inboundFax.status | faxStatus }}</div>
            </div>
            <div class="col-auto">
              <strong>RECEIVED AT</strong>
              <div>{{ selectedAttachment.inboundFax.receivedAt | date: 'M/d/yyyy h:mm a' }}</div>
            </div>
            <div class="col-auto">
              <strong>PROCESSED AT</strong>
              <div>{{ selectedAttachment.inboundFax.processedAt | date: 'MM/dd/yyyy  hh:mm a' | blankValueDash }}</div>
            </div>
            <div class="col-auto">
              <strong>PATIENT</strong>
              <ng-container *ngIf="selectedAttachment.inboundFax.patient; else: noData">
                <div>{{ selectedAttachment.inboundFax.patient.fullName }}</div>
                <div class="small text-nowrap">
                  <strong>MRN:</strong> {{ selectedAttachment.inboundFax.patient.mrn }}
                </div>
              </ng-container>
            </div>
            <div class="col-auto">
              <strong>PRESCRIBER</strong>
              <app-prescriber-name
                [linkToProvider]="true"
                [showSpecialtiesDetails]="true"
                [provider]="selectedAttachment.provider" />
            </div>
          </div>
        </div>
        <app-pdf-file-viewer *ngIf="!usePdfKitViewer"
          [pdfFile]="selectedAttachment"
          [pdfFileType]="pdfFileType"
        >
          <a
            routerLink="/capture-admin/consult-notes/{{selectedAttachment.id}}"
            target="_blank"
            class="btn btn-sm btn-secondary text-white">
            Open Consult Note Page
          </a>
        </app-pdf-file-viewer>
        <div class="row" *ngIf="selectedAttachment.id">
          <div class="col-9"></div>
          <div class="col-3 text-end pb-2">
            <a *ngIf="usePdfKitViewer"
              routerLink="/capture-admin/consult-notes/{{selectedAttachment.id}}"
              target="_blank"
              class="btn btn-sm btn-secondary text-white">
              Open Consult Note Page
            </a>
          </div>
        </div>
        <app-pdfkit-file-viewer *ngIf="usePdfKitViewer && selectedAttachment.url"
          [pdfFile]="selectedAttachment"
          [pdfFileType]="pdfFileType"
          class="card"
          height="80vh"
        />
      </div>
      <ng-template #selectedSpinner> <mat-spinner diameter="60" style="margin: auto;" /> </ng-template>
    </div>
  </div>
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
<ng-template #noOthers> <p>No related consult notes found.</p> </ng-template>
<ng-template #noData> <div><app-blank-value-dash /></div> </ng-template>
