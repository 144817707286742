<form novalidate (keydown.enter)="onFormEnter($event)">
  <div class="dates px-3 py-1 border bg-white rounded-0">
    <app-pagination-header [paging]="paging" (pageSizeChange)="onPageSizeChange($event)" />
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
    <ng-container *ngFor="let verification of verifications; let i = index">
      <app-client-consult-note-task-date
        [verification]="verification"
        [formDisabled]="formDisabled"
        [position]="i + (paging.currentPage - 1) * paging.pageSize"
        (updateVerification)="onUpdate($event)"
        (removeVerification)="onRemove($event)"
      />
    </ng-container>
  </div>
  <div class="mt-2" *ngIf="!formDisabled ">
    <button class="btn btn-sm btn-primary" (click)="onAddClick($event)">
      <fa-icon class="text-white" [icon]="['fas', 'plus-circle']" /> Add Consult Note
    </button>
  </div>
</form>
