import { fromEvent as observableFromEvent, merge, Subscription, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

export class WindowActivityEvent {
  constructor(public active: boolean, public triggeringEvent: Event) {}
}

@Injectable()
export class WindowActivityService implements OnDestroy {
  private active = false;

  private activityEventsSubscription: Subscription;
  private blurEventSubscription: Subscription;
  private visibilityChangedSubscription: Subscription;

  public activity: Subject<WindowActivityEvent> = new Subject();

  readonly windowActivityEvents = ['focus', 'click', 'mousemove', 'scroll', 'keypress', 'resize'];

  constructor() {
    this.subscribeToEvents();
  }

  ngOnDestroy() {
    this.activityEventsSubscription.unsubscribe();
    this.blurEventSubscription.unsubscribe();
    this.visibilityChangedSubscription.unsubscribe();
  }

  private emitActivity(event: Event) {
    const activityEvent = new WindowActivityEvent(this.active, event);

    this.activity.next(activityEvent);
  }

  private setActive(event: Event) {
    this.active = true;
    this.emitActivity(event);
  }

  private setInactive(event: Event) {
    this.active = false;
    this.emitActivity(event);
  }

  private subscribeToEvents() {
    const activityObservables = this.windowActivityEvents.map(event => observableFromEvent(window, event));

    this.activityEventsSubscription = merge(...activityObservables).subscribe(event => {
      this.setActive(event);
    });

    this.blurEventSubscription = observableFromEvent(window, 'blur').subscribe(event => {
      this.setInactive(event);
    });

    this.visibilityChangedSubscription = observableFromEvent(window, 'visibilitychange').subscribe(event => {
      if (document.hidden) {
        this.setInactive(event);
      } else {
        this.setActive(event);
      }
    });
  }
}
