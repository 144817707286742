<app-inbound-fax-header [inboundFax]="inboundFaxInitialState" />

<div class="mt-2">

  <div class="row">
    <div class="col-5">
      <app-inbound-fax-process-form
        [inboundFax]="inboundFax"
        *ngIf="!inboundFax.processed && !inOcrOrLlmProcessing"
        (inboundFaxAttributeChanged)="onInboundFaxAttributeChange($event)"
        [similarInboundFaxState]="similarInboundFaxState"
        (tabChangeRequested)="onTabChangeRequested($event)"
        (nextFaxRequested)="onNextFaxRequested()"
        (skipToNextFaxRequested)="onSkipToNextFaxRequested()"
        (navigateToQueueRequested)="onNavigateToQueueRequested()" />

      <app-inbound-fax-view-ocr-llm-processing
        [inboundFax]="inboundFax"
        *ngIf="inOcrOrLlmProcessing"
        (navigateToQueueRequested)="onNavigateToQueueRequested()" />

      <app-inbound-fax-view-processed
        [inboundFax]="inboundFax"
        *ngIf="inboundFax.processed" />
    </div>
    <div class="col-7">
      <app-inbound-fax-possible-patients
        [inboundFax]="inboundFax" />

      <ng-container *ngIf="inboundFax.url; else: noPdfUrl">
        <app-pdf-basic-viewer
          height="107.5vh"
          pdfKitViewerClass="card"
          [pdfFile]="inboundFax"
          [pdfFileType]="pdfFileType"
          [editable]="!inOcrOrLlmProcessing"
          (urlChange)="onPdfUrlChange($event)" />
      </ng-container>
    </div>
  </div>

</div>

<ng-template #noPdfUrl> No PDF file is associated. </ng-template>
