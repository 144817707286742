<div class="card">
  <div class="card-body alert-warning">
    <div class="row">
      <div class="col-auto">
        <mat-icon
          class="alert-icon text-warning"
          matTooltip="Possible Duplicate">
          warning
        </mat-icon>
      </div>
      <div class="col-auto">
        <p class="mt-2">
          Fax is moving through automatic processing.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="mt-2 actions row">
  <div class="col text-nowrap">
    <button type="button" class="btn btn-secondary" (click)="onQueueClick()">
      Return To Queue
    </button>
  </div>
</div>
