import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Capture } from '../../../core/models/capture.model';
import { Prescription } from '../../../core/models/prescription.model';
import { PagedPrescriptions, PrescriptionsService } from '../../../core/services/prescriptions.service';
import { CaptureChangedEvent, CaptureService } from '../../../core/services/capture.service';
import { HttpErrorResponse } from '@angular/common/http';
import { debounce } from 'lodash-es';

const sortByPrescriber = (npi: string) =>
  (a: Prescription, b: Prescription) => a.prescriber.npi === npi ? -1 : b.prescriber.npi === npi ? 1 : 0;

const sortByActivePrescription = (prescriptionId: number) =>
  (a: Prescription, b: Prescription) => a.id === prescriptionId ? -1 : b.id === prescriptionId ? 1 : 0;

const filterBy = (npi: string, apply: boolean) =>
  (prescription: Prescription) => !apply || prescription.prescriber.npi === npi;

@Component({
  selector: 'app-patient-prescriptions',
  templateUrl: './patient-prescriptions.component.html',
  styleUrls: ['./patient-prescriptions.component.scss'],
})
export class PatientPrescriptionsComponent implements OnInit {
  @Input() capture: Capture;
  @Input() filterByPrescriber: boolean;
  @Input() fixedHeader = true;

  captureChangedSubscription: Subscription = null;
  prescriptions: Prescription[] = [];
  activeCaptureId: number = null;
  activePrescriptionId: number = null;
  prescriberNpi: string = null;
  delayedReload = null;

  debouncedPrescriptionRefresh = debounce(
    () => { this.refreshPrescriptions(); },
    500,
    { maxWait: 1000 }
  );

  constructor(private prescriptionService: PrescriptionsService, private captureService: CaptureService) { }

  ngOnInit() {
    this.init();

    this.captureChangedSubscription = this.captureService.captureChanged.subscribe(
      ({ capture }: CaptureChangedEvent) => {
        if (capture.id === this.activeCaptureId) {
          this.debouncedPrescriptionRefresh();
        }
      }
    );
  }

  private init() {
    this.prescriberNpi = this.capture.candidate.prescriberNpi;
    this.activeCaptureId = this.capture.id;
    this.activePrescriptionId = this.capture.prescription.id;
    this.loadPrescriptions();
  }

  private loadPrescriptions(): void {
    const patientId = this.capture.patient.id;

    this.prescriptionService.getList(
      { patientId, excludeRejectedPatientMatch: true }, null, { pageSize: 'all'}
    ).subscribe(
      ({ prescriptions }: PagedPrescriptions) => {
        this.prescriptions = prescriptions
          .filter(filterBy(this.prescriberNpi, this.filterByPrescriber))
          .sort(sortByPrescriber(this.prescriberNpi))
          .sort(sortByActivePrescription(this.activePrescriptionId));
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );
  }

  private refreshPrescriptions(): void {
    const patientId = this.capture.patient.id;
    const prescriberNpi = this.prescriberNpi;

    this.prescriptionService.getList(
      { patientId, excludeRejectedPatientMatch: true }, null, { pageSize: 'all'}
    ).subscribe(
      ({ prescriptions }: PagedPrescriptions) => {
        prescriptions.forEach(prescription => {
          const index = this.prescriptions.findIndex(p => p.id === prescription.id);

          if (index > -1) {
            this.prescriptions[index] = prescription;
          }
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );
  }
}
