<ng-container *ngIf="!loading; else: spinner">
  <div class="row">
    <div class="col-4">
      <div class="card full-height alert-warning">
        <div class="card-body">
          <app-inbound-fax-duplicate-prompt
            *ngIf="similarInboundFaxes && similarInboundFaxes.length > 0"
            [inboundFax]="inboundFax"
            (submitted)="onDuplicatePromptSubmission($event)" />
        </div>
      </div>
    </div>

    <div class="col-4">

      <div class="card full-height">
        <div class="card-body">
          <h5>Consult Note Information</h5>

          <div class="row">
            <div class="col-auto">
              <div class="header">
                ID
              </div>
              <div>
                {{ selectedSimilarInboundFax.patientAttachment.id }}
              </div>
            </div>
            <div class="col-auto">
              <div class="header">
                Status
              </div>
              <div>
                {{ selectedSimilarInboundFax.patientAttachment.status | patientAttachmentStatus }}
              </div>
            </div>
            <div class="col-auto">
              <div class="header">
                Office
              </div>
              <div>
                <small *ngIf="inboundFax.outboundFax">
                  <app-office [office]="inboundFax.outboundFax.office" />
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-4">

      <div class="card full-height">
        <div class="card-body">
          <h5>Fax Information</h5>

          <div class="row">
            <div class="col-auto">
              <div class="header">
                Status
              </div>
              <div>
                {{ selectedSimilarInboundFax.status | faxStatus }}
              </div>
            </div>
            <div class="col-auto">
              <div class="header">
                Received At
              </div>
              <div>
                {{ selectedSimilarInboundFax.receivedAt | date: 'M/d/yyyy h:mm a' | blankValueDash }}
              </div>
            </div>
            <div class="col-auto">
              <div class="header">
                Patient
              </div>
              <div>
                {{ inboundFax.patient.fullName }}
              </div>
            </div>
            <div class="col-auto">
              <div class="header">
                Prescriber
              </div>
              <div>
                {{ inboundFax.provider.fullName }}
              </div>
            </div>
            <div class="col-auto">
              <div class="header">
                Processed At
              </div>
              <div>
                {{ selectedSimilarInboundFax.processedAt | date: 'M/d/yyyy h:mm a' | blankValueDash }}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="row mt-3">
    <div class="col-6">
      <h5>Current Inbound Fax</h5>

      <ng-container *ngIf="inboundFax.url; else: noPdfUrl">
        <app-pdf-basic-viewer
          height="75vh"
          pdfKitViewerClass="card"
          [pdfFile]="inboundFax"
          [pdfFileType]="pdfFileType"
          pdfKitViewerContainerClass="pspdfkit-container-current-fax"
          [editable]="false"
          (urlChange)="onSimilarInboundFaxPdfUrlChange($event)" />
      </ng-container>
    </div>

    <div class="col-6">
      <h5>
        Viewing:
        {{ selectedSimilarInboundFax.patientAttachment.displayName }} -
        {{ selectedSimilarInboundFax.patientAttachment.createdAt | date: 'M/d/yyyy' }}
        ({{ selectedSimilarInboundFax.lcsRatio }}% Similar)
      </h5>

      <ng-container *ngIf="selectedSimilarInboundFax.url; else: noPdfUrl">
        <app-pdf-basic-viewer
          height="75vh"
          pdfKitViewerClass="card"
          [pdfFile]="selectedSimilarInboundFax"
          [pdfFileType]="pdfFileType"
          pdfKitViewerContainerClass="pspdfkit-container-similar-fax"
          [editable]="false"
          (urlChange)="onSimilarInboundFaxPdfUrlChange($event)" />
      </ng-container>

    </div>
  </div>
</ng-container>

<ng-template #spinner> <mat-spinner /> </ng-template>

<ng-template #noPdfUrl> No PDF file is associated. </ng-template>
