import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InboundFax } from '../../../core/models/inbound-fax.model';
import { SimilarInboundFax } from 'app/core/models/similar-inbound-fax.model';
import { PdfFileType } from '../../../core/enums/pdf-file-type.enum';
import { SimilarInboundFaxState } from 'app/capture-admin/pages/capture-admin-inbound-fax/capture-admin-inbound-fax.component';

@Component({
  selector: 'app-inbound-fax-duplicate-documents-tab',
  templateUrl: './inbound-fax-duplicate-documents-tab.component.html',
  styleUrls: ['./inbound-fax-duplicate-documents-tab.component.scss'],
})
export class InboundFaxDuplicateDocumentsTabComponent {
  pdfFileType: PdfFileType = PdfFileType.inboundFax;

  similarInboundFaxes: SimilarInboundFax[];
  loading = true;
  selectedSimilarInboundFax: SimilarInboundFax;

  @Input() inboundFax: InboundFax;

  @Input()
  set similarInboundFaxState(newState: SimilarInboundFaxState) {
    if (newState.similarInboundFaxes.length > 0) {
      this.loading = false;
      this.similarInboundFaxes = newState.similarInboundFaxes;
      this.selectedSimilarInboundFax = newState.similarInboundFaxes[0];
    } else {
      this.loading = true;
    }
  }

  @Output() duplicateCheckPromptSubmitted =
    new EventEmitter<{ duplicate: boolean, selectedSimilarInboundFax: SimilarInboundFax }>();

  onSelectedSimlarFaxChanged(newSelectedSimilarInboundFax: SimilarInboundFax) {
    this.selectedSimilarInboundFax = newSelectedSimilarInboundFax;
  }

  onDuplicatePromptSubmission(result: { duplicate: boolean }) {
    // Note: The selected fax below doesn't match the selected fax in the UI.
    // This is intentional for now and results in associating the similar fax highest
    // LCS ratio when the form is submitted.
    const updatedResult = {
      ...result,
      selectedSimilarInboundFax: this.similarInboundFaxes[0],
    };

    this.duplicateCheckPromptSubmitted.emit(updatedResult);
  }

  onSimilarInboundFaxPdfUrlChange(url: string) {
    this.selectedSimilarInboundFax.url = url;
  }
}
