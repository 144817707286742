import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { InboundFax } from '../../../core/models/inbound-fax.model';
import { InboundFaxStatus } from '../../../core/enums/inbound-fax-status.enum';
import { InboundFaxStatusReason } from '../../../core/enums/inbound-fax-status-reason.enum';
import { reasonOptions } from '../../../core/options/inbound-fax-status-reasons.opts';

export class InboundFaxOutcome {
  constructor(public status: InboundFaxStatus, public statusReason: InboundFaxStatusReason, public notes: string) { }
}

const shouldSetDefaultStatus = (status: InboundFaxStatus) => ![
  InboundFaxStatus.validEvidence,
  InboundFaxStatus.notValidEvidence,
  InboundFaxStatus.needsManualIntervention,
].includes(status);

@Component({
  selector: 'app-inbound-fax-select-outcome',
  templateUrl: './inbound-fax-select-outcome.component.html'
})
export class InboundFaxSelectOutcomeComponent implements OnInit, OnChanges {
  faxOutcome: InboundFaxOutcome;
  faxStatuses = InboundFaxStatus;
  showNotes = false;
  reasonOptions = reasonOptions.filter(ro => ro.selectable);

  @Input() inboundFax: InboundFax;

  @Output() public outcomeChange = new EventEmitter<InboundFaxOutcome>();

  constructor() { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  onStatusChange() {
    if (this.faxOutcome.status !== InboundFaxStatus.notValidEvidence) {
      this.faxOutcome.statusReason = null;
    }

    this.outcomeChange.emit(this.faxOutcome);
  }

  onStatusReasonChange(reason) {
    if (reason === InboundFaxStatusReason.other && !this.showNotes) {
      this.showNotes = true;
    }

    this.outcomeChange.emit(this.faxOutcome);
  }

  onNotesChange() {
    this.outcomeChange.emit(this.faxOutcome);
  }

  onNotesPlusClick() {
    this.showNotes = true;
  }

  onNotesMinusClick() {
    this.showNotes = false;
  }

  private init() {
    this.faxOutcome =
      new InboundFaxOutcome(this.inboundFax.status, this.inboundFax.statusReason, this.inboundFax.notes);

    if (shouldSetDefaultStatus(this.faxOutcome.status)) {
      this.faxOutcome.status = this.inboundFax.statusClassificationAttempted ?
        InboundFaxStatus.notValidEvidence : InboundFaxStatus.validEvidence;
      this.outcomeChange.emit(this.faxOutcome);
    }
  }
}
