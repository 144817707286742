import { Component, Input } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { Prescription } from '../../../core/models/prescription.model';
import { PagedPrescriptions, PrescriptionsService } from '../../../core/services/prescriptions.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-validate-patient-prescriptions',
  templateUrl: './validate-patient-prescriptions.component.html',
  styleUrls: ['./validate-patient-prescriptions.component.scss'],
})
export class ValidatePatientPrescriptionsComponent {
  _patientAttachment: PatientAttachment;

  prescriptions: Prescription[] = null;
  loading = false;

  constructor(private prescriptionService: PrescriptionsService) {}

  get patientAttachment(): PatientAttachment {
    return this._patientAttachment;
  }

  @Input()
  set patientAttachment(pa: PatientAttachment) {
    this._patientAttachment = pa;

    if (this.prescriptions) {
      this.refreshPrescriptions();
    } else {
      this.loadPrescriptions();
    }
  }

  private loadPrescriptions(): void {
    this.loading = true;
    const patientId = this.patientAttachment.patient.id;

    this.prescriptionService.getList({ patientId, excludeRejectedPatientMatch: true }, null, { pageSize: 'all' }).subscribe(
      ({ prescriptions }: PagedPrescriptions) => {
        this.loading = false;
        this.prescriptions = prescriptions;
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        console.error(error);
      }
    );
  }

  private refreshPrescriptions(): void {
    const patientId = this.patientAttachment.patient.id;

    this.prescriptionService.getList({ patientId, excludeRejectedPatientMatch: true }, null, { pageSize: 'all' }).subscribe(
      ({ prescriptions }: PagedPrescriptions) => {
        prescriptions.forEach(prescription => {
          const index = this.prescriptions.findIndex(p => p.id === prescription.id);

          if (index > -1) {
            this.prescriptions[index] = prescription;
          }
        });
      },
      (error: HttpErrorResponse) => {
        console.error(error);
      }
    );
  }
}
