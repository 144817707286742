import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LimitOffsetPaging } from '../../../core/models/paged-results/limit-offset-paging.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pagination-header',
  templateUrl: './pagination-header.component.html',
  styleUrls: ['./pagination-header.component.scss'],
})
export class PaginationHeaderComponent implements OnInit {
  @Input() paging: LimitOffsetPaging;
  @Input() label: string;
  @Input() updateUrl = false;
  @Input() allowLargeSize = false;
  @Input() includeResetFiltersButton = false;

  pageSizes = [5, 10, 20, 50, 100];

  @Output() public pageSizeChange = new EventEmitter<number>();
  @Output() public resetFiltersClick = new EventEmitter<void>();

  constructor(private route: ActivatedRoute, private router: Router) {}

  get viewing() {
    return Math.min(this.paging.pageSize, this.paging.count);
  }

  get visible() {
    return !this.includeResetFiltersButton && this.paging.count > Math.min(...this.pageSizes)
  }

  ngOnInit() {
    if (this.allowLargeSize) this.pageSizes.push(500);
  }

  onPageSizeChange(event) {
    const size = event.target.value;
    this.updatePageSizeParam(size);
    this.pageSizeChange.emit(size);
  }

  onResetFiltersClick($event) {
    $event.preventDefault();
    this.resetFiltersClick.emit();
  }

  private updatePageSizeParam(size) {
    if (this.updateUrl) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { pageSize: size },
        queryParamsHandling: 'merge',
      });
    }
  }
}
