import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Audit } from '../models/audit.model';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { LimitOffsetPagingMeta, Paging } from '../models/paged-results/limit-offset-paging.model';

export class PagedAudits implements PagedResults {
  audits: Audit[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.audits;
  }
}

@Injectable()
export class AuditsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(
    paging: Paging = null,
    auditableType: string,
    auditableIds: number[],
    associations = false
  ): Observable<PagedAudits> {
    const params = new URLSearchParams();

    if (paging) {
      params.append('page', paging.page.toString());
      params.append('per_page', paging.pageSize.toString());
    }

    params.append('auditable_type', auditableType);
    auditableIds.forEach(auditableId => {
      params.append('auditable_id[]', auditableId.toString());
    });

    params.append('associations', associations.toString());

    return this.http.get<PagedAudits>(`${environment.captureApi.url}/audits?${params.toString()}`);
  }
}
