<div class="border border-dark border-2 rounded-2 pt-3 h-100">
  <a class="expand-more"
     *ngIf="hasData"
     href="javascript:;"
     (click)="openGraphInModal($event)"
     title="Enlarge the chart"><mat-icon class="material-symbols-outlined">expand_content</mat-icon>
  </a>
  <h5 class="fs-4 text-black text-center">Net Savings Trend by Claim Source</h5>
  <h6 class="fs-6 text-black text-center">Last 13 Months</h6>
  <ng-container *ngIf="hasData">
    <app-client-net-savings-trend-by-source-graph *ngIf="!loadingData; else: spinner"
                                                  [clientNetSavingsTrendBySources]="clientNetSavingsTrendBySources" />
  </ng-container>
  <div *ngIf="!hasData" class="text-center py-5">
    There's no net savings trend by source.
  </div>
</div>

<ng-template #spinner>
  <mat-spinner diameter="30" />
</ng-template>
