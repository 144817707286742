import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileCheck } from '../models/file-check.model';
import { environment } from '../../../environments/environment';
import { LimitOffsetPagingMeta, Paging } from "../models/paged-results/limit-offset-paging.model";
import { FileCheckFilters } from "../models/user-settings/file-check-settings.model";
import { PagedResults } from "../models/paged-results/paged-results.model";

export class PagedFileChecks implements PagedResults {
  private fileChecks: FileCheck[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.fileChecks;
  }
}

@Injectable()
export class FileChecksService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id): Observable<FileCheck> {
    return this.http.get<FileCheck>(`${environment.captureApi.url}/file_checks/${id}`);
  }

  getList(
    paging: Paging,
    filters: FileCheckFilters = null
  ): Observable<PagedFileChecks> {
    const searchParams = new URLSearchParams(`page=${paging.page}&per_page=${paging.pageSize}`);

    if (filters) {
      if (filters.clientIds && filters.clientIds.length > 0) {
        filters.clientIds.forEach(v => searchParams.append('client_ids[]', v.toString()));
      }
    }

    return this.http.get<PagedFileChecks>(
      `${environment.captureApi.url}/file_checks?${searchParams.toString()}`
    ).pipe(
      map(result => Object.assign(new PagedFileChecks(), result))
    );
  }

  create(fileType, clientId, signedId): Observable<FileCheck> {
    return this.http.post<FileCheck>(`${environment.captureApi.url}/file_checks`, {
      fileCheck: { fileType, clientId, signedId },
    });
  }

  cancel(id): Observable<FileCheck> {
    return this.http.patch<FileCheck>(`${environment.captureApi.url}/file_checks/${id}/cancel`, {});
  }
}
