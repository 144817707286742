import { mergeMap } from 'rxjs/operators';
import { debounce, isNull } from 'lodash-es';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClientFilesService, PagedClientFiles } from 'app/core/services/client-files.service';
import { SqsQueueSummaryService } from 'app/core/services/sqs-queue-summary.service';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { ClientsService } from '../../../core/services/clients.service';
import { DownloadService } from 'app/core/services/download.service';
import { Client } from '../../../core/models/client.model';
import { ClientFile } from 'app/core/models/client-file.model';
import { SqsQueueSummary } from 'app/core/models/sqs-queue-summary.model';
import { interval } from 'rxjs';

const sqsQueueSummaryPollingInterval = 10000;

@Component({
  selector: 'app-capture-admin-client-files',
  templateUrl: './capture-admin-client-files.component.html',
  styleUrls: ['./capture-admin-client-files.component.scss'],
})
export class CaptureAdminClientFilesComponent implements OnInit, OnDestroy {
  clientFiles: ClientFile[] = [];
  sqsQueueSummary: SqsQueueSummary;
  sqsQueueSummaryPolling;
  paging: LimitOffsetPaging;
  loading = true;
  formModel: any = {};
  clients: Client[] = [];
  allCollapsed = true;
  collapsedClientFiles = {};
  previousFilename: string = null;

  debounceFilenameKeyUp = debounce(
    () => {
      if (this.previousFilename !== this.formModel.filename) {
        this.loadClientFiles(1);
      }
    },
    350,
    { maxWait: 1000 }
  );

  constructor(
    private clientsService: ClientsService,
    private downloadService: DownloadService,
    private clientFilesService: ClientFilesService,
    private sqsQueueInfosService: SqsQueueSummaryService
  ) {}

  ngOnInit() {
    this.init();
  }

  private init() {
    this.paging = LimitOffsetPaging.empty;
    this.loadSqsQueueSummary();
    this.loadClients();
    this.loadClientFiles(1);
    this.resetFormModel();
    this.sqsQueueSummaryPollingInit();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadClientFiles(this.paging.currentPage);
  }

  onPageChange(page: number) {
    this.loadClientFiles(page);
  }

  private updateAllCollapsed() {
    this.allCollapsed = Object.keys(this.collapsedClientFiles).every(k => this.collapsedClientFiles[k] === true);
  }

  private loadClientFiles(page: number) {
    this.loading = true;
    this.clientFilesService
      .getList(page, this.paging.pageSize, this.formModel.selectedClientIds, this.formModel.filename)
      .subscribe((result: PagedClientFiles) => {
        this.clientFiles = result.clientFiles;
        this.paging = result.meta.paging;
        this.setClientFileCollapsedForAll(true);
        this.loading = false;
      });
  }

  private loadClients() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }

  private loadSqsQueueSummary() {
    this.sqsQueueInfosService.getSummary().subscribe((result: SqsQueueSummary) => {
      this.sqsQueueSummary = result;
    });
  }

  private resetFormModel() {
    this.formModel = {
      selectedClientIds: this.formModel.selectedClientIds ? [...this.formModel.selectedClientIds] : [],
      filename: '',
    };
  }

  onClearAllClick($event: any) {
    this.formModel.selectedClientIds = new Array<number>();
    this.formModel.filename = null;
    this.resetFormModel();
    this.loadClientFiles(1);
    $event.preventDefault();
  }

  onClientChange() {
    this.loadClientFiles(1);
  }

  onToggleClick(clientFile: ClientFile) {
    this.collapsedClientFiles[clientFile.id] = !this.collapsedClientFiles[clientFile.id];
    this.updateAllCollapsed();
  }

  onItemClose(clientFile: ClientFile) {
    this.collapsedClientFiles[clientFile.id] = true;
    this.updateAllCollapsed();
  }

  clientFileCollapsed(clientFile: ClientFile) {
    return this.collapsedClientFiles[clientFile.id];
  }

  onToggleAllClick($event) {
    $event.preventDefault();
    this.allCollapsed = !this.allCollapsed;
    this.setClientFileCollapsedForAll(this.allCollapsed);
  }

  private setClientFileCollapsedForAll(flag) {
    const collapsedClientFiles = {};

    this.clientFiles.forEach(t => {
      collapsedClientFiles[t.id] = flag;
    });

    this.collapsedClientFiles = collapsedClientFiles;
    this.updateAllCollapsed();
  }

  get hasFilename(): boolean {
    const value = this.formModel.filename;
    return !(isNull(value) || value === '');
  }

  onFilenameKeyDown($event) {
    this.previousFilename = this.formModel.filename;
  }

  onFilenameKeyUp() {
    this.debounceFilenameKeyUp();
  }

  onClearFilenameClick() {
    this.formModel.filename = null;
    this.loadClientFiles(1);
  }

  onFormEnter($event: any) {
    $event.preventDefault();
    this.loadClientFiles(1);
  }

  onDownloadErrorsClick($event: any, clientFile: ClientFile) {
    $event.preventDefault();
    this.clientFilesService.generateErrorFileAndGetDownloadUrl(clientFile.id).subscribe(url => {
      this.downloadService.download(url.download_url);
    });
  }

  ngOnDestroy() {
    if (this.sqsQueueSummaryPolling) {
      this.sqsQueueSummaryPolling.unsubscribe();
      this.sqsQueueSummaryPolling = null;
    }
  }

  private sqsQueueSummaryPollingInit() {
    this.sqsQueueSummaryPolling = interval(sqsQueueSummaryPollingInterval)
      .pipe(mergeMap(() => this.sqsQueueInfosService.getSummary()))
      .subscribe(result => {
        console.log(this.sqsQueueSummary);
        this.sqsQueueSummary = result;
      });
  }
}
