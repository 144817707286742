import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InboundFax } from '../../../core/models/inbound-fax.model';

@Component({
  selector: 'app-inbound-fax-view-ocr-llm-processing',
  templateUrl: './inbound-fax-view-ocr-llm-processing.component.html',
  styleUrls: ['./inbound-fax-view-ocr-llm-processing.component.scss'],
})
export class InboundFaxViewOcrLlmProcessingComponent {
  @Input() inboundFax: InboundFax;
  @Output() navigateToQueueRequested = new EventEmitter<void>();

  onQueueClick() {
    this.navigateToQueueRequested.emit();
  }
}
