<div class="modal-body">
  <a class="close-modal"
      href="javascript:;"
      (click)="closeModal($event)"
      title="Close Window"><mat-icon class="material-symbols-outlined">close</mat-icon>
  </a>
  <h5 class="fs-4 text-black text-center">Net Savings Trend by Claim Source</h5>
  <h6 class="fs-6 text-black text-center">Last 13 Months</h6>
  <app-client-net-savings-trend-by-source-graph
    [clientNetSavingsTrendBySources]="clientNetSavingsTrendBySources"
    [canvasId]="'canvas-modal'" />
</div>

