import { remove } from 'lodash-es';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ClientConsultNoteTask } from '../../../core/models/client-consult-note-task.model';
import { ClientConsultNoteTaskChoice } from '../../../core/enums/client-consult-note-task-choice.enum';
import { Prescription } from '../../../core/models/prescription.model';
import { Sort, LimitOffsetPaging } from '../../../core/models/paged-results/limit-offset-paging.model';
import { PagedPrescriptions, PrescriptionsService } from '../../../core/services/prescriptions.service';
import { ClientConsultNoteVerification } from '../../../core/models/client-consult-note-verification.model';
import {
  ClientConsultNoteVerificationsService,
  PagedClientConsultNoteVerifications,
} from '../../../core/services/client-consult-note-verifications.service';

@Component({
  selector: 'app-client-consult-note-task-verification',
  templateUrl: './client-consult-note-task-verification.component.html',
  styleUrls: ['./client-consult-note-task-verification.scss'],
})
export class ClientConsultNoteTaskVerificationComponent implements OnInit, OnChanges {
  isYesSelected = false;
  loadingVerifications = true;
  loadingPrescriptions = true;
  showPrescriptionsWarning = false;
  verifications: ClientConsultNoteVerification[] = new Array<ClientConsultNoteVerification>();
  prescriptions: Prescription[] = new Array<Prescription>();
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;

  @Input() task: ClientConsultNoteTask;
  @Input() formDisabled: boolean;
  @Output() taskChange = new EventEmitter<ClientConsultNoteTask>();

  constructor(
    private verificationsService: ClientConsultNoteVerificationsService,
    private prescriptionsService: PrescriptionsService
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadVerifications(this.paging.currentPage);
  }

  onPageChange(page: number) {
    this.loadVerifications(page);
  }

  onTaskChange(task: ClientConsultNoteTask) {
    this.taskChange.emit(task);
  }

  onAddVerification() {
    this.verificationsService.create(this.task.patientPrescriberId).subscribe(verification => {
      const verifications = [...this.verifications];
      verifications.push(verification);
      this.verifications = verifications;
      this.loadPrescriptions();
    });
  }

  onUpdateVerification(updated: { id: number; encounterDate: string }) {
    this.verificationsService.update(updated.id, updated.encounterDate).subscribe(v => {
      const index = this.verifications.findIndex(verification => verification.id === v.id);
      const verifications = [...this.verifications];

      if (index > -1) {
        verifications[index] = v;
      }

      this.verifications = verifications;
      this.loadPrescriptions();
    });
  }

  onRemoveVerification(id: number) {
    this.verificationsService.destroy(id).subscribe(() => {
      const verifications = [...this.verifications];

      remove(verifications, v => v.id === id);

      this.verifications = verifications;

      this.loadPrescriptions();
    });
  }

  private init() {
    this.isYesSelected = this.task.choice === ClientConsultNoteTaskChoice.yes;
    this.loadVerifications(this.paging.currentPage);
    this.loadPrescriptions();
  }

  private loadVerifications(page: number) {
    const paging = { page, pageSize: this.paging.pageSize };
    this.loadingVerifications = true;

    this.verificationsService
      .getList({ patientPrescriberId: this.task.patientPrescriberId }, paging)
      .subscribe((result: PagedClientConsultNoteVerifications) => {
        this.verifications = result.clientConsultNoteVerifications;
        this.loadingVerifications = false;
        this.paging = result.meta.paging;
      });
  }

  private loadPrescriptions() {
    this.loadingPrescriptions = true;

    const sort = new Sort();
    sort.col = 'written_date';
    sort.dir = 'desc';

    this.prescriptionsService
      .getList({ patientPrescriberId: this.task.patientPrescriberId }, sort, {pageSize: 'all'})
      .subscribe((result: PagedPrescriptions) => {
        this.prescriptions = result.prescriptions;
        this.showPrescriptionsWarning =
          this.isYesSelected && this.prescriptions.some(p => p.nearestClientConsultNoteVerificationInvalid);
        this.loadingPrescriptions = false;
      });
  }
}
