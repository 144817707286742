<app-pdf-file-viewer
  *ngIf="!usePdfKitViewer"
  [height]="height"
  [class]="pdfFileViewerClass"
  [pdfFile]="pdfFile"
  [pdfFileType]="pdfFileType" />
<app-pdfkit-file-viewer
  *ngIf="usePdfKitViewer"
  [height]="height"
  [class]="pdfKitViewerClass"
  [containerClass]="pdfKitViewerContainerClass"
  [pdfFile]="pdfFile"
  [pdfFileType]="pdfFileType"
  [editable]="editable"
  (urlChange)="onUrlChange($event)" />
