import { Injectable } from '@angular/core';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { User } from '../models/user.model';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { SessionStorageService } from 'app/core/services/session-storage.service';
import { SentryService } from './sentry.service';
import { JwtToken, JwtTokenType } from '../models/jwt-token.model';
import { Client } from '../models/client.model';
import { LogoutReason } from '../enums/logout-reason.enum';
import { UserPasswordCredentials } from '../models/user-password-credentials.model';
import { LoginAttemptStatus } from '../enums/login-attempt-status.enum';
import { ChangePasswordStatus } from '../enums/change-password-status.enum';
import { LoginType } from '../enums/login-type.enum';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UserSession } from '../models/user-session.model';
import { environment } from '../../../environments/environment';
import { DateUtils } from '../lib/date-utils';
import { AppTabIdSessionKey, UserSessionKey } from '../constants';
import { isCePortalUser, UserType } from '../enums/user-type.enum';

const userLoginEventKey = 'userLoginEvent';
const userLogoutEventKey = 'userLogoutEvent';
const viewAsClientKey = 'captureAdminViewAsClient';
const userDeviceKey = 'userDevice';

export enum RefreshTokenResult {
  NoSession,
  Failed,
  Succeeded,
}

export enum PerformMfaResult {
  Failed,
  Succeeded,
}

export class AuthenticateUserResponse {
  public userSession: UserSession;
  public userDevice: string;
  public userEmail: string;
}

export class LoginTypeResponse {
  public loginType: string;
  public samlUrl: URL;
}

export class UserLoginEvent {
  constructor(public appTabId: string) {}
}

export class UserLogoutEvent {
  constructor(public appTabId: string, public logoutReason: LogoutReason) {}
}

export class LoginAttemptResult {
  constructor(public status: LoginAttemptStatus, public data: object = null) {}
}

export class ChangePasswordResult {
  constructor(public status: ChangePasswordStatus, public data: object = null) {}
}

export class LoginTypeResult {
  constructor(public type: LoginType, public data: object = null) {}
}

const parseAuthEvent = (storageEvent: StorageEvent) => {
  if (storageEvent.newValue) {
    return typeof storageEvent.newValue === 'string' ? JSON.parse(storageEvent.newValue) : storageEvent.newValue;
  } else {
    return null;
  }
};

declare let pendo: any;

const pendoIsEnabled = () => pendo && environment.pendo.enabled;

const initializePendoIdentity = (user: User, clientUser: boolean) => {
  if (pendoIsEnabled()) {
    pendo.initialize({ excludeAllText: true });

    const visitorProps = {
      id: user.email, //required
      // add any k/v pairs we want to slice and dice segments:
      // values must be string bool int or float
      userType: user.userType,
      roles: user.userRoles.map(ur => ur.role).join(', '),
    };

    const clientProps = {};

    if (clientUser && user.availableClients.length === 1) {
      const client = user.availableClients[0];
      clientProps['clientName'] = client.name;
      clientProps['clientEntityTypes'] = client.entityTypes.join(',');
      clientProps['clientState'] = client.state;
    }

    pendo.identify({
      visitor: {...visitorProps, ...clientProps},
      account: {
        id: environment.pendo.accountIdentifier, //required
        // add any k/v pairs we want to slice and dice account segments below:
        // values must be string bool int or float
        ...clientProps
      },
    });
  }
};

const buildUserDeviceKey = (userName: string) => `${userDeviceKey}.${userName}`;

@Injectable()
export class AuthenticationService {
  readonly loginSubscription: Subscription = null;
  readonly logoutSubscription: Subscription = null;

  private userLoginSource: Subject<UserLoginEvent> = new Subject<UserLoginEvent>();
  private userLogoutSource: Subject<UserLogoutEvent> = new Subject<UserLogoutEvent>();

  private startedViewingAsClientSource: Subject<Client> = new Subject<Client>();
  private stoppedViewingAsClientSource: Subject<Client> = new Subject<Client>();

  public currentSession: UserSession = null;
  public currentUser: User = null;

  public userLogin: Observable<UserLoginEvent> = this.userLoginSource.asObservable();
  public userLogout: Observable<UserLogoutEvent> = this.userLogoutSource.asObservable();

  public startedViewingAsClient: Observable<Client> = this.startedViewingAsClientSource.asObservable();
  public stoppedViewingAsClient: Observable<Client> = this.stoppedViewingAsClientSource.asObservable();

  constructor(
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private sentryService: SentryService,
    private http: HttpClient
  ) {
    this.loginSubscription = this.localStorageService.observe(userLoginEventKey).subscribe(
      (storageEvent: StorageEvent) => {
        const userLoginEvent: UserLoginEvent = parseAuthEvent(storageEvent);

        this.handleSyncLoginEvent(userLoginEvent);
      },
      (err: any) => {
        this.sentryService.logException(err);
      }
    );

    this.logoutSubscription = this.localStorageService.observe(userLogoutEventKey).subscribe(
      (storageEvent: StorageEvent) => {
        const userLogoutEvent: UserLogoutEvent = parseAuthEvent(storageEvent);

        this.handleSyncLogoutEvent(userLogoutEvent);
      },
      (err: any) => {
        this.sentryService.logException(err);
      }
    );
  }

  public get jwtIdentity(): string {
    return this.currentSession && this.currentSession.idToken;
  }

  public get isLoggedIn(): boolean {
    return this.currentSession && this.isSessionValid();
  }

  public get isInMaintenance(): boolean {
    return environment.maintenance;
  }

  public get userSub(): string {
    return (this.currentUser && this.currentUser.sub) || 'unauthenticated-user';
  }

  public get loggedInAt(): Date {
    return this.idToken && this.idToken.authenticatedAt;
  }

  public get idToken(): JwtToken {
    return this.getToken(JwtTokenType.Id);
  }

  public get accessToken(): JwtToken {
    return this.getToken(JwtTokenType.Access);
  }

  public get currentUsername() {
    return (this.currentSession && this.currentSession.username) || 'unknown-user';
  }

  public get isCaptureAdminUser(): boolean {
    return this.currentUser && this.currentUser.userType === UserType.captureAdmin && !this.viewingAsClient;
  }

  public get isInternalConsultantUser(): boolean {
    return this.currentUser?.userType === UserType.cePortalInternalConsultant;
  }

  public get isClientUser(): boolean {
    return this.currentUser && (isCePortalUser(this.currentUser.userType) || !!this.viewingAsClient);
  }

  public get isLlmPlaygroundUser(): boolean {
    return this.currentUser && (
      this.currentUser.userType === UserType.llmPlaygroundUser ||
      this.currentUser.userType === UserType.captureAdmin
    )
  }

  public get isPartnerUser(): boolean {
    return this.currentUser && this.currentUser.userType === UserType.partnerPortal;
  }

  public hasPermissionTo(userRoleName: string): boolean {
    return this.currentUser && this.currentUser.userRoles.some(role => role.role === userRoleName && role.enabled);
  }

  public get viewingAsClient(): Client {
    return this.localStorageService.get(viewAsClientKey);
  }

  public get appTabId(): string {
    return this.sessionStorageService.get(AppTabIdSessionKey);
  }

  public get isEulaRequired(): boolean {
    return this.currentUser && this.currentUser.eulaRequired;
  }

  public get isMfaPhoneRequired(): boolean {
    return this.currentUser && this.currentUser.mfaPhoneRequired;
  }

  public get allClientEntityTypes(): string[] {
    if (this.viewingAsClient) {
      return this.viewingAsClient.entityTypes || [];
    } else if (this.currentUser) {
      return this.currentUser.availableClients.flatMap(client => client.entityTypes || []);
    } else {
      return [];
    }
  }

  public updateCurrentUser(user: User) {
    this.currentUser = user;
  }

  public reloadSession(): Observable<any> {
    this.loadSession();

    if (this.isLoggedIn) {
      return this.loadUserProfile();
    } else {
      return this.refreshTokens();
    }
  }

  public determineLoginType({ email }: UserPasswordCredentials): Observable<LoginTypeResult> {
    return this.http
      .post<LoginTypeResponse>(`${environment.captureApi.url}/sessions/login_type`, {
        email,
      })
      .pipe(
        map((resp: LoginTypeResponse) => new LoginTypeResult(LoginType[resp['loginType']], resp['samlUrl'])),
        catchError((err: any) => {
          console.log(err);
          return of(new LoginTypeResult(LoginType.UnexpectedFailure));
        })
      );
  }

  public verifySession(): Observable<boolean> {
    if (!this.isLoggedIn) {
      return this.refreshTokens().pipe(
        map((result: RefreshTokenResult) => {
          if (result === RefreshTokenResult.Failed) {
            this.logout(LogoutReason.failedToRefreshTokens);
          }
          return result === RefreshTokenResult.Succeeded;
        }),
        catchError(err => {
          console.error(err);
          return of(false);
        })
      );
    } else {
      return of(true);
    }
  }

  public login({ email, password }: UserPasswordCredentials): Observable<LoginAttemptResult> {
    const deviceKey = buildUserDeviceKey(email);
    const device = this.localStorageService.get(deviceKey);
    const credentials = { username: email, password, device };

    return this.http
      .post<AuthenticateUserResponse>(`${environment.captureApi.url}/sessions`, {
        credentials,
      })
      .pipe(
        tap((resp: AuthenticateUserResponse) => {
          this.saveSession(email, resp.userSession);
          this.saveDevice(email, resp.userDevice);
        }),
        mergeMap(() => this.loadUserProfile()),
        map(() => {
          if (this.currentSession && this.currentUser) {
            this.syncUserLogin();
            return new LoginAttemptResult(LoginAttemptStatus.Authenticated);
          } else {
            return new LoginAttemptResult(LoginAttemptStatus.UnexpectedFailure);
          }
        }),
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.error.error) {
              case 'UserNotFoundException':
              case 'NotAuthorizedException':
                return of(new LoginAttemptResult(LoginAttemptStatus.InvalidCredentials));
              case 'UserDisabledException':
                return of(new LoginAttemptResult(LoginAttemptStatus.AccountDisabled));
              case 'UserLockedOutException':
                return of(new LoginAttemptResult(LoginAttemptStatus.AccountLocked));
              case 'ForceResetPasswordException':
                return of(new LoginAttemptResult(LoginAttemptStatus.ForceResetPassword, err.error.data));
              case 'MFARequiredException':
                return of(new LoginAttemptResult(LoginAttemptStatus.MFARequired, err.error.data));
              case 'DeviceNotAuthorizedException':
                this.localStorageService.remove(deviceKey);
                return of(new LoginAttemptResult(LoginAttemptStatus.DeviceNotAuthorized));
              default:
                this.sentryService.logMessage(err.message);
                this.localStorageService.remove(deviceKey);
                return of(new LoginAttemptResult(LoginAttemptStatus.UnexpectedFailure));
            }
          } else {
            this.sentryService.logException(err);
            return of(new LoginAttemptResult(LoginAttemptStatus.UnexpectedFailure));
          }
        })
      );
  }

  public loginFromCode(code: string): Observable<LoginAttemptResult> {
    return this.http
      .post<AuthenticateUserResponse>(`${environment.captureApi.url}/sessions/create_from_code`, {
        code,
      })
      .pipe(
        tap((resp: AuthenticateUserResponse) => {
          this.saveSession(resp.userEmail, resp.userSession);
        }),
        mergeMap(() => this.loadUserProfile()),
        map(() => {
          if (this.currentSession && this.currentUser) {
            this.syncUserLogin();
            return new LoginAttemptResult(LoginAttemptStatus.Authenticated);
          } else {
            return new LoginAttemptResult(LoginAttemptStatus.UnexpectedFailure);
          }
        }),
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.error.error) {
              case 'UserNotFoundException':
              case 'NotAuthorizedException':
                return of(new LoginAttemptResult(LoginAttemptStatus.InvalidCredentials, err.error.message));
              case 'UserDisabledException':
                return of(new LoginAttemptResult(LoginAttemptStatus.AccountDisabled, err.error.message));
              case 'UserLockedOutException':
                return of(new LoginAttemptResult(LoginAttemptStatus.AccountLocked, err.error.message));
              default:
                this.sentryService.logMessage(err.message);
                return of(new LoginAttemptResult(LoginAttemptStatus.UnexpectedFailure, err.error.message));
            }
          } else {
            this.sentryService.logException(err);
            return of(new LoginAttemptResult(LoginAttemptStatus.UnexpectedFailure, err.error.message));
          }
        })
      );
  }
  public logout(logoutReason: LogoutReason) {
    if (this.currentSession) {
      //only call the logout endpoint if we have a session - we may not always
      this.http.delete(`${environment.captureApi.url}/sessions/logout`, { params: { reason: logoutReason } }).subscribe(
        () => {},
        err => {
          console.error(err);
        }
      );
    }

    const username = this.currentUsername;
    let message = `${username} is being logged out`;
    message = `${message} due to: ${logoutReason}`;

    console.log(message);

    this.localStorageService.remove(UserSessionKey);
    this.currentSession = null;
    this.currentUser = null;
    this.stopViewingAsClient();
    this.syncUserLogout(logoutReason);
  }

  public changePassword(
    username: string,
    code: string,
    newPassword: string,
    confirmNewPassword: string
  ): Observable<ChangePasswordResult> {
    if (newPassword !== confirmNewPassword) {
      return of(new ChangePasswordResult(ChangePasswordStatus.PasswordsDoNotMatch));
    }

    return this.http
      .patch<AuthenticateUserResponse>(`${environment.captureApi.url}/me/change_password`, {
        credentials: { code, newPassword, confirmNewPassword },
      })
      .pipe(
        tap((resp: AuthenticateUserResponse) => {
          this.saveSession(username, resp.userSession);
          this.saveDevice(username, resp.userDevice);
        }),
        mergeMap(() => this.loadUserProfile()),
        map(() => {
          if (this.currentSession && this.currentUser) {
            this.syncUserLogin();
            return new ChangePasswordResult(ChangePasswordStatus.Success);
          } else {
            return new ChangePasswordResult(ChangePasswordStatus.UnexpectedFailure);
          }
        }),
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.error.error) {
              case 'ExpiredCodeException':
                return of(new ChangePasswordResult(ChangePasswordStatus.ExpiredCode));
              case 'InvalidPasswordException':
                return of(new ChangePasswordResult(ChangePasswordStatus.PasswordViolatesPolicy));
              case 'PasswordAlreadyUsedException':
                return of(new ChangePasswordResult(ChangePasswordStatus.PasswordAlreadyUsed));
              case 'MFARequiredException':
                return of(new ChangePasswordResult(ChangePasswordStatus.MFARequired, err.error.data));
              default:
                this.sentryService.logMessage(err.message);
                return of(new ChangePasswordResult(ChangePasswordStatus.UnexpectedFailure));
            }
          } else {
            this.sentryService.logException(err);
            return of(new ChangePasswordResult(ChangePasswordStatus.UnexpectedFailure));
          }
        })
      );
  }

  public refreshTokens(): Observable<RefreshTokenResult> {
    if (!this.currentSession) {
      return of(RefreshTokenResult.NoSession);
    }

    const username = this.currentUsername;
    const deviceKey = buildUserDeviceKey(username);
    const device = this.localStorageService.get(deviceKey);
    const userSession = { refreshToken: this.currentSession.refreshToken, device };

    return this.http
      .patch<UserSession>(`${environment.captureApi.url}/sessions/refresh`, {
        session: userSession,
      })
      .pipe(
        tap((session: UserSession) => {
          this.saveSession(username, session);
        }),
        mergeMap(() => this.loadUserProfile()),
        map(() => {
          if (this.currentSession && this.currentUser) {
            return RefreshTokenResult.Succeeded;
          } else {
            return RefreshTokenResult.Failed;
          }
        }),
        catchError(() => {
          return of(RefreshTokenResult.Failed);
        })
      );
  }

  public performMfa(username: string, session: string, code: string): Observable<PerformMfaResult> {
    return this.http
      .patch<AuthenticateUserResponse>(`${environment.captureApi.url}/sessions/perform_mfa`, {
        mfa: { username, session, code },
      })
      .pipe(
        tap((resp: AuthenticateUserResponse) => {
          this.saveSession(username, resp.userSession);
          this.saveDevice(username, resp.userDevice);
        }),
        mergeMap(() => this.loadUserProfile()),
        map(() => {
          if (this.currentSession && this.currentUser) {
            this.syncUserLogin();
            return PerformMfaResult.Succeeded;
          } else {
            return PerformMfaResult.Failed;
          }
        }),
        catchError((err: any) => {
          this.sentryService.logMessage(err.message);
          return of(PerformMfaResult.Failed);
        })
      );
  }

  public startViewingAsClient(client: Client) {
    if (this.isCaptureAdminUser) {
      this.localStorageService.set(viewAsClientKey, client);
      this.startedViewingAsClientSource.next(client);
    }
  }

  public stopViewingAsClient() {
    const client = this.viewingAsClient;
    this.localStorageService.remove(viewAsClientKey);
    this.stoppedViewingAsClientSource.next(client);
  }

  public sessionCheckIn(): Observable<void> {
    return this.http.patch<void>(`${environment.captureApi.url}/sessions/check_in`, {})
  }

  private loadUserProfile(): Observable<User> {
    if (this.isLoggedIn) {
      return this.http
        .get<User>(`${environment.captureApi.url}/me`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.jwtIdentity}`,
          },
        })
        .pipe(
          tap((user: User) => {
            this.currentUser = user;
            this.logCurrentSession();
            return user;
          }),
          catchError(err => {
            this.logout(LogoutReason.failedToLoadProfile);
            return throwError(err);
          })
        );
    } else {
      return of(null);
    }
  }

  private syncUserLogin() {
    const event = new UserLoginEvent(this.appTabId)

    // Sync this tab
    this.handleSyncLoginEvent(event);

    // Sync other tabs
    this.localStorageService.set(userLoginEventKey, event);
    this.localStorageService.remove(userLoginEventKey);
  }

  private syncUserLogout(logoutReason: LogoutReason) {
    const event = new UserLogoutEvent(this.appTabId, logoutReason)

    // Sync this tab
    this.handleSyncLogoutEvent(event);

    // Sync other tabs
    this.localStorageService.set(userLogoutEventKey, event);
    this.localStorageService.remove(userLogoutEventKey);
  }

  private handleSyncLoginEvent(userLoginEvent: UserLoginEvent) {
    if (userLoginEvent) {
      if (userLoginEvent.appTabId !== this.appTabId) {
        this.reloadSession().subscribe(() => {
          this.userLoginSource.next(userLoginEvent);
        });
      } else {
        this.userLoginSource.next(userLoginEvent);
      }
    }
  }

  private handleSyncLogoutEvent(userLogoutEvent: UserLogoutEvent) {
    if (userLogoutEvent) {
      if (userLogoutEvent.appTabId !== this.appTabId) {
        this.currentUser = null;
        this.currentSession = null;
      }

      this.userLogoutSource.next(userLogoutEvent);
    }
  }

  private logCurrentSession() {
    if (this.isLoggedIn) {
      const lastLoginAt = DateUtils.formatDateTime(this.loggedInAt);
      const msg = `Initialized session for ${this.currentUsername}: last login was at ${lastLoginAt}`;
      initializePendoIdentity(this.currentUser, this.isClientUser);
      console.log(msg);
    }
  }

  private getToken(tokenType: JwtTokenType) {
    if (!this.currentSession) {
      return null;
    }

    try {
      let decodedToken = null;

      switch (tokenType) {
        case JwtTokenType.Id:
          decodedToken = jwtDecode(this.currentSession.idToken);
          break;
        case JwtTokenType.Access:
          decodedToken = jwtDecode(this.currentSession.accessToken);
          break;
      }
      return decodedToken ? new JwtToken(decodedToken) : null;
    } catch (err) {
      this.sentryService.logException(err);
      return null;
    }
  }

  private saveSession(username: string, session: UserSession) {
    this.currentSession = session;
    this.updateCurrentSession(username);
    this.localStorageService.set<UserSession>(UserSessionKey, session);
  }

  private saveDevice(userName: string, device: string) {
    if (device) {
      this.localStorageService.set<string>(buildUserDeviceKey(userName), device);
    }
  }

  private loadSession() {
    this.currentSession = this.localStorageService.get(UserSessionKey);
  }

  private isSessionValid(): boolean {
    if (this.accessToken && this.idToken) {
      const now = Math.floor(Number(new Date()) / 1000);
      const adjusted = now - this.currentSession.clockDrift || 0;
      return adjusted < this.accessToken.expiresAtTimestamp && adjusted < this.idToken.expiresAtTimestamp;
    } else {
      return false;
    }
  }

  private updateCurrentSession(username: string) {
    if (this.currentSession) {
      const now = Math.floor(Number(new Date()) / 1000);
      const iat = Math.min(this.accessToken.issuedAtTimestamp, this.idToken.issuedAtTimestamp);
      this.currentSession.clockDrift = now - iat;
      this.currentSession.username = username;
    }
  }
}
