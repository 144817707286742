<div class="row">
  <div class="col-auto">
    <mat-icon
      class="alert-icon text-warning"
      matTooltip="Possible Duplicate">
      warning
    </mat-icon>
  </div>
  <div class="col">
    <p>
      <strong>
        After reviewing the patient’s documents, would you consider the current fax
        (ID {{ inboundFax.id }}) a duplicate note?
      </strong>
    </p>

    <form [formGroup]="formGroup">
      <div class="form-check">
        <input type="radio"
               class="form-check-input"
               id="duplicate-yes"
               name="duplicate"
               formControlName="duplicate"
               [value]="true" />

        <label class="form-check-label" for="duplicate-yes">
          Yes, this is a duplicate note.  Mark as Invalid and close the fax.
        </label>
      </div>

      <div class="form-check">
        <label class="form-check-label" for="duplicate-no">
        <input type="radio"
               class="form-check-input"
               id="duplicate-no"
               name="duplicate"
               formControlName="duplicate"
               [value]="false" />
          No, this is not a duplicate note.  Continue to Process Fax.
        </label>
      </div>

      <div class="mt-2">
        <button type="button" class="btn btn-primary" (click)="onSubmitClick()" [disabled]="!submitEnabled">
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
