import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Patient } from 'app/core/models/patient.model';
import { Prescription } from 'app/core/models/prescription.model';
import { PrescriptionsService, PagedPrescriptions } from 'app/core/services/prescriptions.service';
import { PatientService } from 'app/core/services/patient.service';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';

@Component({
  selector: 'app-capture-admin-patient-prescriptions',
  templateUrl: './capture-admin-patient-prescriptions.component.html',
  styleUrls: ['./capture-admin-patient-prescriptions.component.scss'],
})
export class CaptureAdminPatientPrescriptionsComponent implements OnInit {
  patient: Patient;
  loading = true;
  prescriptions: Prescription[] = [];
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;

  constructor(
    private prescriptionsService: PrescriptionsService,
    private patientService: PatientService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.init();
  }

  private loadPrescriptions() {
    const patientId = this.getPatientId();
    this.loading = true;

    this.prescriptionsService.getList(
      { patientId },
      null,
      { page: this.paging.currentPage, pageSize: this.paging.pageSize }
    ).pipe(catchError(this.handleLoadingError)
    ).subscribe((result: PagedPrescriptions) => {
      if (result) {
        this.prescriptions = result.prescriptions;
        this.paging = result.meta.paging;
      }
      this.loading = false;
    });
  }

  private init() {
    const patientId = this.getPatientId();
    this.loading = true;

    this.patientService.get(patientId)
      .pipe(catchError(this.handleLoadingError))
      .subscribe(patient => {
        if (patient) {
          this.patient = patient;
          this.loadPrescriptions();
        }
      });
  }

  handleLoadingError(error: HttpErrorResponse) {
    this.loading = false;
    console.error(error);
    return of(null);
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.paging.currentPage = 1;
    this.loadPrescriptions();
  }

  onPageChange(page: number) {
    this.paging.currentPage = page;
    this.loadPrescriptions();
  }

  private getPatientId(): number {
    const id = this.route.snapshot.paramMap.get('id');
    return parseInt(id, 10);
  }
}
