import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { InboundFax } from '../../../core/models/inbound-fax.model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { InboundFaxesService } from '../../../core/services/inbound-faxes.service';
import { UserTimingService } from '../../../core/services/user-timing.service';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { UserPresenceService } from 'app/core/services/user-presence.service';
import { SimilarInboundFax } from 'app/core/models/similar-inbound-fax.model';
import { PdfFileType } from '../../../core/enums/pdf-file-type.enum';
import { sortBy } from 'lodash-es';
import { InboundFaxStatus } from 'app/core/enums/inbound-fax-status.enum';
import { InboundFaxStatusReason } from 'app/core/enums/inbound-fax-status-reason.enum';
import { InboundFaxAttributeChangeType } from 'app/capture-admin/components/inbound-fax-process-form/inbound-fax-process-form.component';

const shouldReloadFax =
  (inboundFax: InboundFax, inboundFaxId) => inboundFax && inboundFaxId !== inboundFax.id.toString();

export class SimilarInboundFaxState {
  fetching = false;
  fetched = false;
  warningDismissed = false;
  similarInboundFaxes: SimilarInboundFax[] = [];
}

@Component({
  templateUrl: './capture-admin-inbound-fax.component.html',
  styleUrls: ['./capture-admin-inbound-fax.component.scss'],
})
export class CaptureAdminInboundFaxComponent implements OnInit, OnDestroy {
  inboundFaxId: string;
  inboundFax: InboundFax;
  inboundFaxInitialState: InboundFax;

  refreshing = true;

  currentTab = 'main';
  showTabs = false;

  similarInboundFaxState = new SimilarInboundFaxState();

  pdfFileType: PdfFileType = PdfFileType.inboundFax;

  inboundFaxSource = '';

  private static similarInboundFaxLimit = 1;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private inboundFaxesService: InboundFaxesService,
    private router: Router,
    private titleService: Title,
    private userPresenceService: UserPresenceService,
    private userTimingService: UserTimingService,
  ) {
    this.route.paramMap.subscribe(paramsMap => {
      this.handleRouteChange(paramsMap);
    });
  }

  ngOnInit() {
    this.inboundFaxId = this.route.snapshot.paramMap.get('id');
    this.currentTab = this.route.snapshot.paramMap.get('tab') || 'main';

    if (this.router.url.match('my-work')) {
      this.inboundFaxSource = 'my';
    }

    this.loadInboundFax(this.inboundFaxId);
  }

  ngOnDestroy(): void {
    this.userTimingService.stop();
    this.userPresenceService.leaveAndUnsubscribe("fax", this.inboundFaxId);
  }

  onInboundFaxAttributeChange(type: InboundFaxAttributeChangeType) {
    if (type === InboundFaxAttributeChangeType.patient ||
      type === InboundFaxAttributeChangeType.prescriber ||
      type === InboundFaxAttributeChangeType.outboundFax) {

      this.clearSimilarInboundFaxState();
      this.getSimilarFaxes();
    }
  }

  changeTab(newTab: string) {
    this.currentTab = newTab;

    const tabPath = [];

    if (this.route.snapshot.paramMap.get('tab')) {
      tabPath.push('..')
    }
    if (this.currentTab !== 'main') {
      tabPath.push(this.currentTab);
    }

    const url = this.router.createUrlTree(tabPath, { relativeTo: this.route }).toString();
    this.location.go(url);
  }

  onTabChangeRequested(newTab: string) {
    this.changeTab(newTab);
  }

  onDuplicateCheckPromptSubmitted(result: { duplicate: boolean, selectedSimilarInboundFax: SimilarInboundFax }) {
    const newState = { ...this.similarInboundFaxState };
    newState.warningDismissed = result.duplicate === false;
    this.similarInboundFaxState = newState;

    if (result.duplicate) {
      this.submitDuplicateInboundFax(result.selectedSimilarInboundFax);
    } else {
      this.changeTab('main');
    }
  }

  onNextFaxRequested() {
    this.goToNextFax();
  }

  onSkipToNextFaxRequested() {
    this.skipToNextFax();
  }

  onNavigateToQueueRequested() {
    this.navigateToQueue();
  }

  get duplicateDocumentsTabTooltip() {
    if (this.similarInboundFaxState.fetched) {
      if (this.similarInboundFaxState.similarInboundFaxes.length > 0) {
        return null;
      } else {
        return "No duplicates found.";
      }
    } else {
      return "Please select patient, prescriber, and outbound fax";
    }
  }

  private handleRouteChange(paramsMap: ParamMap) {
    const params = paramsMap['params'];
    const inboundFaxId = params && params['id'];

    if (shouldReloadFax(this.inboundFax, inboundFaxId)) {
      this.userPresenceService.leaveAndUnsubscribe("fax", this.inboundFaxId);
      this.currentTab = 'main';
      this.clearSimilarInboundFaxState();
      this.inboundFaxId = inboundFaxId;
      this.loadInboundFax(inboundFaxId);
    }
  }

  private loadInboundFax(inboundFaxId): void {
    this.refreshing = true;
    this.inboundFaxesService.get(inboundFaxId).subscribe(
      inboundFax => {
        this.inboundFax = inboundFax;
        this.inboundFaxInitialState = { ...inboundFax };
        this.refreshing = false;
        this.updateTitle();
        this.startTimeTracking();
        this.userPresenceService.subscribeAndJoin("fax", this.inboundFaxId);
        this.getSimilarFaxes();
        this.updateTabState();
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.router.navigate(['/404']);
        } else {
          console.error(err);
        }
      }
    );
    window.scrollTo(0, 0);
  }

  private updateTitle() {
    const titleDetail = this.inboundFax.patient ? this.inboundFax.patient.mrn : this.inboundFax.client.humanizedName;
    const title = `R1 340B Recovery - View Fax | ${titleDetail}`;
    this.titleService.setTitle(title);
  }

  private startTimeTracking() {
    this.userTimingService.track({
      page: 'inbound_fax_details',
      recordId: this.inboundFax.id,
      status: this.inboundFax.status,
    });
  }

  private getSimilarFaxes() {
    if (this.inboundFax &&
      this.inboundFax.patient &&
      this.inboundFax.prescriber &&
      this.inboundFax.outboundFax) {

      if (!this.similarInboundFaxState.fetched) {
        const newState = new SimilarInboundFaxState();
        newState.fetching = true;
        this.similarInboundFaxState = newState;

        const request = this.inboundFaxesService.getSimlarPatientOfficeInboundFaxes(
          this.inboundFax,
          this.inboundFax.patient.id,
          this.inboundFax.outboundFax.id,
          CaptureAdminInboundFaxComponent.similarInboundFaxLimit
        );

        request.subscribe(similarInboundFaxes => {
          const newState = new SimilarInboundFaxState()
          newState.fetching = false;
          newState.fetched = true;
          newState.similarInboundFaxes = sortBy(similarInboundFaxes, ['lcsRatio']).reverse();
          this.similarInboundFaxState = newState;
        });
      }
    } else {
      this.clearSimilarInboundFaxState();
    }
  }

  private clearSimilarInboundFaxState() {
    this.similarInboundFaxState = new SimilarInboundFaxState();
  }

  private submitDuplicateInboundFax(similarInboundFax: SimilarInboundFax) {
    this.inboundFax.status = InboundFaxStatus.notValidEvidence;
    this.inboundFax.statusReason = InboundFaxStatusReason.duplicate;
    this.inboundFax.duplicateInboundFax = similarInboundFax;
    this.inboundFax.duplicateInboundFaxLcsRatio = similarInboundFax.lcsRatio;

    this.inboundFaxesService.transition(this.inboundFax).subscribe(
      () => {
        this.goToNextFax();
      },
      (err: HttpErrorResponse) => {
        console.error(err);
      }
    );
  }

  private goToNextFax() {
    this.inboundFaxesService.getNextFax(this.inboundFaxSource).subscribe(({ item }) => {
      this.navigateToNextFaxIfAvailable(item);
    });
  }

  private skipToNextFax() {
    this.inboundFaxesService.skipToNextFax(this.inboundFaxSource).subscribe(({ item }) => {
      this.navigateToNextFaxIfAvailable(item);
    });
  }

  private navigateToNextFaxIfAvailable(nextFax: InboundFax) {
    if (nextFax) {
      this.router.navigate([`${this.navigationPrefix()}/${nextFax.id}`]);
    } else {
      this.navigateToQueue();
    }
  }

  private navigateToQueue() {
    this.router.navigate([this.navigationPrefix()]);
  }

  private navigationPrefix() {
    if (this.inboundFaxSource === 'my') {
      return '/capture-admin/my-work/faxes';
    } else {
      return '/capture-admin/communications/inbound-faxes';
    }
  }

  private updateTabState() {
    this.showTabs = !this.inboundFax.processed && !this.inOcrOrLlmProcessing();

    if (!this.showTabs && this.currentTab !== 'main') {
      this.changeTab('main');
    }
  }

  inOcrOrLlmProcessing() {
    return this.inboundFax.status === InboundFaxStatus.inOcr ||
      this.inboundFax.status === InboundFaxStatus.llmProcessing;
  }
}
