import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Provider } from '../models/provider.model';
import { ProviderMechanicalTurkReview } from '../models/provider-mechanical-turk-review.model';
import { Encounter } from '../models/encounter.model';
import { ApiService } from './api.service';
import { ProviderOffice } from '../models/provider-office.model';
import { LimitOffsetPagingMeta, Sort, Paging } from '../models/paged-results/limit-offset-paging.model';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { ProviderFilters } from '../models/user-settings/provider-search-settings.model';
import { Audit } from '../models/audit.model';

export class PagedProviders implements PagedResults {
  private providers: Provider[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.providers;
  }
}


export class PagedProviderMechanicalTurkReviews implements PagedResults {
  private providers: ProviderMechanicalTurkReview[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.providers;
  }
}

export class PagedProviderEncounters implements PagedResults {
  private encounters: Encounter[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.encounters;
  }
}

export class PagedProviderAudits implements PagedResults {
  private audits: Audit[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.audits;
  }
}

export class ProviderOfficeFilters {
  acceptableFaxStatusOnly: boolean = false;
  withSentOutboundFaxesForPatientPrescriber: number = null;
}

@Injectable()
export class ProviderService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(
    paging: Paging,
    filters: ProviderFilters = null
  ): Observable<PagedProviders> {
    const searchParams = new URLSearchParams(`page=${paging.page}&per_page=${paging.pageSize}`);

    if (filters) {
      if (filters.clientId) {
        searchParams.append('client_id', filters.clientId.toString());
      }

      if (filters.firstName) {
        searchParams.append('first_name', filters.firstName);
      }

      if (filters.lastName) {
        searchParams.append('last_name', filters.lastName);
      }

      if (filters.npi) {
        searchParams.append('npi', filters.npi);
      }

      if (filters.city) {
        searchParams.append('city', filters.city);
      }

      if (filters.state) {
        searchParams.append('state', filters.state);
      }

      if (filters.status && filters.status.length > 0) {
        filters.status.forEach(v => searchParams.append('status[]', v.toString()));
      }
    }

    return this.http.get<PagedProviders>(
      `${environment.captureApi.url}/providers?${searchParams.toString()}`
    ).pipe(
      map(result => Object.assign(new PagedProviders(), result))
    );
  }

  getMechanicalTurkReviewList(
    paging: Paging,
    sort: Sort
  ): Observable<PagedProviderMechanicalTurkReviews> {
    const searchParams = new URLSearchParams(`page=${paging.page}&per_page=${paging.pageSize}`);

    if (sort.col) {
      searchParams.append('sort_col', sort.col);
    }

    return this.http.get<PagedProviderMechanicalTurkReviews>(
      `${environment.captureApi.url}/providers/mechanical_turk_reviews?${searchParams.toString()}`
    ).pipe(
      map(result => Object.assign(new PagedProviderMechanicalTurkReviews(), result))
    );
  }

  encounters(id: number, eligible: boolean,
    paging: Paging,
  ): Observable<PagedProviderEncounters> {

    const searchParams = new URLSearchParams(`page=${paging.page}&per_page=${paging.pageSize}`);
    searchParams.append('eligible', eligible.toString());

    return this.http.get<PagedProviderEncounters>(
      `${environment.captureApi.url}/providers/${id}/encounters?${searchParams.toString()}`
    ).pipe(
      map(result => Object.assign(new PagedProviderEncounters(), result))
    );
  }

  get(id: number): Observable<Provider> {
    return this.http.get<Provider>(`${environment.captureApi.url}/providers/${id}`);
  }

  getByNpi(npi: string, validateNpi = false): Observable<Provider> {
    let url = `${environment.captureApi.url}/providers/by_npi/${npi}`

    if (validateNpi) {
      url += '?validate_npi=true'
    }

    return this.http.get<Provider>(url);
  }

  getProviderOffices(
    providerId: number,
    filters: ProviderOfficeFilters = new ProviderOfficeFilters(),
  ): Observable<ProviderOffice[]> {
    const filterParams: URLSearchParams = new URLSearchParams();

    if (filters.acceptableFaxStatusOnly) {
      filterParams.append('acceptable_fax_status_only', 'yes');
    }

    if (filters.withSentOutboundFaxesForPatientPrescriber) {
      filterParams.append(
        'with_sent_outbound_faxes_for_patient_prescriber',
        filters.withSentOutboundFaxesForPatientPrescriber.toString()
      );
    }

    return this.http.get<ProviderOffice[]>(
      `${environment.captureApi.url}/providers/${providerId}/provider_offices?${filterParams.toString()}`
    );
  }

  create(attributes: any): Observable<Provider> {
    return this.http.post<Provider>(`${environment.captureApi.url}/providers`, {
      provider: attributes,
    });
  }

  update(provider: Provider, attributes: any): Observable<Provider> {
    return this.http.patch<Provider>(`${environment.captureApi.url}/providers/${provider.id}`, {
      provider: attributes,
    });
  }

  getAudits(
    provider: Provider,
    paging: Paging,
  ): Observable<PagedProviderAudits> {
    const searchParams = new URLSearchParams(`page=${paging.page}&per_page=${paging.pageSize}`);

    return this.http.get<PagedProviderAudits>(
      `${environment.captureApi.url}/providers/${provider.id}/audits?${searchParams.toString()}`
    ).pipe(
      map(result => Object.assign(new PagedProviderAudits(), result))
    );
  }
}
