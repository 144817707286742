import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { PagedResults } from 'app/core/models/paged-results/paged-results.model';
import { Prescription } from 'app/core/models/prescription.model';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { PrescriptionCapture } from 'app/core/models/capture-lists/prescription-capture-model';
import { ClientPrescriptionMatch } from 'app/core/models/client-prescription-match.model';
import { LimitOffsetPaging, LimitOffsetPagingMeta, Sort, Paging } from 'app/core/models/paged-results/limit-offset-paging.model';

export class PagedPrescriptions implements PagedResults {
  prescriptions: Prescription[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.prescriptions;
  }

  static get empty(): PagedPrescriptions {
    const pagedPrescriptions = new PagedPrescriptions();
    pagedPrescriptions.prescriptions = new Array<Prescription>();
    pagedPrescriptions.meta = new LimitOffsetPagingMeta();
    pagedPrescriptions.meta.paging = LimitOffsetPaging.empty;
    return pagedPrescriptions;
  }
}

@Injectable()
export class PrescriptionsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(prescriptionId: number): Observable<Prescription> {
    const url = `${environment.captureApi.url}/prescriptions/${prescriptionId}`;

    return this.http.get<Prescription>(url);
  }

  getList(
    filters: {
      patientId?: number;
      prescriberNpi?: string;
      patientPrescriberId?: number;
      excludeRejectedPatientMatch?: boolean;
    },
    sort: Sort = null,
    paging: Paging
  ): Observable<PagedPrescriptions> {
    let url = `${environment.captureApi.url}/prescriptions?page=${paging.page}&per_page=${paging.pageSize}`;

    if (filters.patientId) {
      url = `${url}&patient_id=${filters.patientId}`;
    }

    if (filters.prescriberNpi) {
      url = `${url}&prescriber_npi=${filters.prescriberNpi}`;
    }

    if (filters.patientPrescriberId) {
      url = `${url}&patient_prescriber_id=${filters.patientPrescriberId}`;
    }

    if (filters.excludeRejectedPatientMatch) {
      url = `${url}&exclude_rejected_patient_match=yes`;
    }

    if (sort && sort.col && sort.dir) {
      url = `${url}&sort_col=${sort.col}&sort_dir=${sort.dir}`;
    }

    return this.http.get<PagedPrescriptions>(url);
  }

  getCaptures(prescription: Prescription): Observable<PrescriptionCapture[]> {
    return this.http.get<PrescriptionCapture[]>(
      `${environment.captureApi.url}/prescriptions/${prescription.id}/captures`
    );
  }

  getClientPrescriptionMatches(
    prescription: Prescription
  ): Observable<{ clientPrescriptionMatches: ClientPrescriptionMatch[] }> {
    return this.http.get<{ clientPrescriptionMatches: ClientPrescriptionMatch[] }>(
      `${environment.captureApi.url}/prescriptions/${prescription.id}/client_prescription_matches`
    );
  }
}
