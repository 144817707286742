import { Component, Input, OnInit } from '@angular/core';
import { PatientAttachmentLlmResultSet } from 'app/core/models/patient-attachment-llm-result-set.model';
import { AppToastService } from 'app/core/services/app-toast.service';
import { LlmFeedbackService } from 'app/core/services/llm-feedback.service';

@Component({
  selector: 'app-consult-note-summary',
  templateUrl: './consult-note-summary.component.html',
  styleUrls: ['./consult-note-summary.component.scss']
})
export class ConsultNoteSummaryComponent implements OnInit {
  @Input() resultSet: PatientAttachmentLlmResultSet;
  summary: Record<string, any>;
  formCollapsed = true;
  feedback: string;
  showNewSpecialty = false;

  constructor(
    private llmFeedbackService: LlmFeedbackService,
    private toastService: AppToastService
  ) { }

  ngOnInit(): void {
    if (this.resultSet) {
      this.summary = this.resultSet.summary;
      if (this.summary?.specialtyDetail?.specialty) {
        this.showNewSpecialty = true;
      }
    }
    this.resetForm();
  }

  onSaveClick(): void {
    this.llmFeedbackService.submitResultSetFeedback(this.resultSet.id, this.feedback).subscribe({
      complete: () => {
        this.toastService.show('Feedback submitted. Thanks!', { cssClass: 'bg-success-subtle' });
        this.resetForm();
      },
      error: () => this.toastService.show('An error occurred.', { cssClass: 'bg-danger-subtle' })
    });
  }

  private resetForm(): void {
    this.formCollapsed = true;
    this.feedback = "";
  }

}
