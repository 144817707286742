import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientNetSavingsTrendBySources } from 'app/core/models/client-net-savings-trend-by-sources.model';
import { Client } from 'app/core/models/client.model';
import { ClientsService } from 'app/core/services/clients.service';
import { ClientNetSavingsTrendBySourceGraphModalComponent } from '../client-net-savings-trend-by-source-graph-modal/client-net-savings-trend-by-source-graph-modal.component';

@Component({
  selector: 'app-client-net-savings-trend-by-source',
  templateUrl: './client-net-savings-trend-by-source.component.html',
  styleUrls: ['./client-net-savings-trend-by-source.component.scss'],
})
export class ClientNetSavingsTrendBySourceComponent implements OnInit {
  @Input() client: Client;
  loadingData = true;
  hasData = true;
  clientNetSavingsTrendBySources: ClientNetSavingsTrendBySources;

  constructor(
    private clientsService: ClientsService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.init();
  }

  openGraphInModal($event) {
    $event.preventDefault();

    const modalRef = this.modalService.open(ClientNetSavingsTrendBySourceGraphModalComponent, {
      windowClass: 'graph-modal',
    });
    modalRef.componentInstance.clientNetSavingsTrendBySources = this.clientNetSavingsTrendBySources;
  }

  private init() {
    this.clientsService.getNetSavingsTrendBySource(this.client.id).subscribe(results => {
      this.clientNetSavingsTrendBySources = results;
      this.loadingData = false;
    },
    error => {
      this.loadingData = false;
      this.hasData = false;
    });
  }
}