import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from './core/services/navigation.service';
import { UserSettingsService } from './core/services/user-settings.service';
import { BrowserService } from './core/services/browser.service';
import { HelpScoutService } from './core/services/help-scout.service';
import { UserSettings } from './core/enums/user-settings.enum';
import { AuthenticationService } from './core/services/authentication.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmMfaPhoneModalComponent } from './shared/components/confirm-mfa-phone-modal/confirm-mfa-phone-modal.component';
import { AppToastService } from './core/services/app-toast.service';

const TasksPages = {
  clientConsultNoteTasks: 'tasks/client-consult-note-tasks',
  clientPrescriberTasks: 'tasks/client-prescriber-tasks',
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  userLoginSubscription = null;
  userLogoutSubscription = null;
  pageChangedSubscription = null;
  confirmPhoneModal: NgbModalRef = null;
  browserSupported: boolean;
  showFatFooter: boolean;
  legacyLayout = true;

  constructor(
    private authService: AuthenticationService,
    private navigationService: NavigationService,
    private userSettingsService: UserSettingsService,
    private browserService: BrowserService,
    private modalService: NgbModal,
    private helpScoutService: HelpScoutService,
    private toastService: AppToastService
  ) {}

  ngOnInit() {
    this.browserSupported = this.browserService.determineSupport();
    this.showFatFooter = this.navigationService.showFatFooter();

    if (this.authService.isLoggedIn && !this.authService.isCaptureAdminUser) {
      this.helpScoutService.initialize();
    }

    this.userLoginSubscription = this.authService.userLogin.subscribe(() => {
      if (!this.authService.isCaptureAdminUser) {
        this.helpScoutService.initialize();
      }
    });

    this.userLogoutSubscription = this.authService.userLogout.subscribe(() => {
      if (!this.authService.isCaptureAdminUser) {
        this.helpScoutService.destroy();
      }
      this.closeConfirmPhoneModal();
    });
  }

  ngAfterContentInit() {
    this.pageChangedSubscription = this.navigationService.pageChanged.subscribe(({ url }) => {
      this.updateLastTasksSectionPage(url);
      this.showFatFooter = this.navigationService.showFatFooter();
      this.legacyLayout = !this.navigationService.inSubSection('capture-admin');

      // make sure we clear any ngb-toast objects if
      // they are not set to autohide.
      this.toastService.clear();

      if (this.authService.isMfaPhoneRequired && !this.confirmPhoneModal) {
        this.openConfirmPhoneModal();
      }
    });
  }

  ngOnDestroy() {
    if (this.userLoginSubscription) {
      this.userLogoutSubscription.unsubscribe();
    }

    if (this.userLogoutSubscription) {
      this.userLogoutSubscription.unsubscribe();
    }

    if (this.pageChangedSubscription) {
      this.pageChangedSubscription.unsubscribe();
    }

    this.closeConfirmPhoneModal();
  }

  updateLastTasksSectionPage(url) {
    let lastTasksSectionPage = null;

    if (url.includes(TasksPages.clientConsultNoteTasks)) {
      lastTasksSectionPage = TasksPages.clientConsultNoteTasks;
    } else if (url.includes(TasksPages.clientPrescriberTasks)) {
      lastTasksSectionPage = TasksPages.clientPrescriberTasks;
    }

    if (lastTasksSectionPage) {
      this.userSettingsService.save(UserSettings.userNavigationSettings, { lastTasksSectionPage });
    }
  }

  private openConfirmPhoneModal() {
    this.confirmPhoneModal = this.modalService.open(ConfirmMfaPhoneModalComponent, {
      beforeDismiss: () => false,
    });
  }

  private closeConfirmPhoneModal() {
    if (this.confirmPhoneModal) {
      this.confirmPhoneModal.close();
      this.confirmPhoneModal = null;
    }
  }
}
